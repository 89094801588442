//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";

//Element
function About(){
    //Element
    const { ID } = useParams()
    //Element
    const [ W5C70T, J7R62I ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://www.eskaklima.com/API/LPLH3FV97T", {
                //Element
                params : { BKC7XQ : ID }
            })
            //Element
            J7R62I( Public.data )
        }
        //Element
        Fetch()
    }, [])
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 w28f14" },
        //Element
        W5C70T.map( (Data) => {
            //Element
            return React.createElement( "div", { key: Data.BKC7XQ }, Parser( Data.U0AQYD ) )
        })
    )
    //Element
    return(
        <div className="float-start w-100">
            { Content }
        </div>
    )
}
//Element
export default About;