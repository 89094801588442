//Install
import React, { Component } from "react";
//Install
import Speaking from "speakingurl";
//Install
import propTypes from "prop-types";

//Element
class Product extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo } = this.props
        //Element
        let Folder = "https://www.eskaklima.com/upload/"
        //Element
        let Image  = Folder + Photo
        //Element
        let Clean  = Speaking( Title )
        //Element
        let Slug   = "/"
        //Element
        let Param  = "/urun/"
        //Element
        let URL    = Param + Clean + Slug + ID
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 i0z16z", title: Title, href: URL },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 q1a02x" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "295", height: "147" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 x6r112" },
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-truncate text-center i3549y" }, Title )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Product.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired
}
//Element
export default Product;