//Install
import React, { useState, useEffect } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
//Install
import { useParams } from "react-router-dom";
//Install
import { ReactComponent as L10138 } from "../../../Media/Icon/009.svg";
//Install
import { ReactComponent as Q437V1 } from "../../../Media/Icon/010.svg";
//Install
import Axios from "axios";

//Element
function Photo(){
    //Element
    SwiperCore.use([ Autoplay, Navigation, Pagination ])
    //Element
    const Photos = {
        //Element
        spaceBetween  : 0,
        //Element
        slidesPerView : 1,
        //Element
        navigation    : {
            //Element
            nextEl    : '#Web_W34Y97',
            //Element
            prevEl    : '#Web_K96MJ8'
        },
        //Element
        autoplay      : {
            //Element
            delay     : 2000
        },
        //Element
        loop          : true
    }
    //Element
    const { ID } = useParams()
    //Element
    const [ Y18103, DN1J52 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://www.eskaklima.com/API/YIVMRSFC6Y", {
                //Element
                params : { BPT7WX : ID }
            })
            //Element
            DN1J52( Public.data )
        }
        //Element
        Fetch()
    }, [])
    //Element
    let Image = "https://www.eskaklima.com/upload/"
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 z6m3n3" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative rg5423" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Photos },
                //Element
                Y18103.map( (Data) => {
                    //Element
                    return React.createElement( SwiperSlide, { key: Data.DCU0I0 },
                        //Element
                        React.createElement( "img", { alt: Data.R1LXGA, title: Data.R1LXGA, src: Image + Data.UC1R2C, width: "636", height: "318" } )
                    )
                })
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 r3081r" },
                //Element
                React.createElement( "button", { id: "Web_W34Y97", name: "Web_W34Y97", className: "float-start p-0 m-0 border-0 position-relative rounded-circle of320a" },
                    //Element
                    React.createElement( Q437V1, {} )
                ),
                //Element
                React.createElement( "button", { id: "Web_K96MJ8", name: "Web_K96MJ8", className: "float-start p-0 m-0 border-0 position-relative rounded-circle of320a" },
                    //Element
                    React.createElement( L10138, {} )
                )
            )
        )
    )
    //Element
    return(
        <div className="float-start w-100">
            { Content }
        </div>
    )
}
//Element
export default Photo;