//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as C28D6J } from "../../../Media/Icon/008.svg";
//Install
import Multiple from "../Tab/VRF";
//Install
import Hybrid from "../Tab/Hybrid";

//Element
class VRF extends Component {
    //Element
    Multiple_Tab = () => {
        //Element
        let Multiple, Multiples, Hybrid, Hybrids
        //Element
        Multiple  = document.getElementById( "Web_JP544O" )
        //Element
        Multiples = document.getElementById( "Web_H8L0Y5" )
        //Element
        Hybrid    = document.getElementById( "Web_S050T4" )
        //Element
        Hybrids   = document.getElementById( "Web_Y615O0" )
        //Element
        Multiple.classList.add( "d-block" )
        //Element
        Multiples.classList.add( "a30e10" )
        //Element
        Hybrid.classList.remove( "d-block" )
        //Element
        Hybrids.classList.remove( "a30e10" )
    }
    //Element
    Hybrid_Tab = () => {
        //Element
        let Multiple, Multiples, Hybrid, Hybrids
        //Element
        Multiple  = document.getElementById( "Web_JP544O" )
        //Element
        Multiples = document.getElementById( "Web_H8L0Y5" )
        //Element
        Hybrid    = document.getElementById( "Web_S050T4" )
        //Element
        Hybrids   = document.getElementById( "Web_Y615O0" )
        //Element
        Multiple.classList.remove( "d-block" )
        //Element
        Multiples.classList.remove( "a30e10" )
        //Element
        Hybrid.classList.add( "d-block" )
        //Element
        Hybrids.classList.add( "a30e10" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 g537rv" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 gl6479" },
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 r5163w" },
                             //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_H8L0Y5", name: "Web_H8L0Y5", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3 a30e10", type: "button", onClick: this.Multiple_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Multi VRF Sistemleri" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            ),
                             //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_Y615O0", name: "Web_Y615O0", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3", type: "button", onClick: this.Hybrid_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Hybrid VRF Sistemleri" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:9 },
                    //Element
                    React.createElement( "div", { id: "Web_JP544O", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0 d-block" },
                        //Element
                        React.createElement( Multiple, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: "Web_S050T4", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0" },
                        //Element
                        React.createElement( Hybrid, {} )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default VRF;