//Install
import React, { Component } from "react";
//Install
import { ReactComponent as IBQJ3Z } from "../../Media/Social/Facebook.svg";
//Install
import { ReactComponent as A29OYW } from "../../Media/Social/Instagram.svg";
//Install
import { ReactComponent as ROW1UP } from "../../Media/Social/Youtube.svg";
//Install
import propTypes from "prop-types";

//Element
class Social extends Component {
    //Element
    render() {
        //Element
        const { Facebook, Instagram, Youtube } = this.props
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ih8riw" },
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "d-flex justify-content-center align-items-center p-0 m-0 me-3 f5qfag", title: "Facebook", href: Facebook },
                    //Element
                    React.createElement( IBQJ3Z, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "d-flex justify-content-center align-items-center p-0 m-0 me-3 wxam0p", title: "Instagram", href: Instagram },
                    //Element
                    React.createElement( A29OYW, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "d-flex justify-content-center align-items-center p-0 m-0 olle9e", title: "Youtube", href: Youtube },
                    //Element
                    React.createElement( ROW1UP, {} )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Social.propTypes = {
    ID        : propTypes.string.isRequired,
    Facebook  : propTypes.string.isRequired,
    Instagram : propTypes.string.isRequired,
    Youtube   : propTypes.string.isRequired
}
//Element
export default Social;