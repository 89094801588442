//Install
import React, { Component } from "react";
//Instlal
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Slider extends Component {
    //Element
    render() {
        //Element
        const { Title, Headline, Summary, Describe, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 h-100 p-0 m-0 zr41d4", title: Title, href: Target },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 v986gy" },
                        //Element
                        React.createElement( "figure", { className: "float-start w-100 p-0 m-0 h1r483" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 t90i22" },
                        //Element
                        React.createElement( "h1", { className: "float-start p-0 m-0 text-center x09f37" }, Title ),
                        //Element
                        React.createElement( "h1", { className: "float-start p-0 m-0 text-center lh-1 e9l174" }, Headline ),
                        //Element
                        React.createElement( "p", { className: "float-start p-0 m-0 mt-3 text-center t2f180" }, Summary ),
                        //Element
                        React.createElement( "p", { className: "float-start p-0 m-0 mt-3 text-center a82g3r" }, Describe ),
                        //Element
                        React.createElement( "div", { className: "d-flex justify-content-center align-items-center p-0 m-0 mt-4 e80c17" },
                            //Element
                            React.createElement( "span", { className: "float-start p-0 m-0 y1928i" }, "Hemen Bakın" )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Slider.propTypes = {
    ID       : propTypes.string.isRequired,
    Title    : propTypes.string.isRequired,
    Headline : propTypes.string.isRequired,
    Summary  : propTypes.string.isRequired,
    Describe : propTypes.string.isRequired,
    Target   : propTypes.string.isRequired
}
//Element
export default Slider;