//Install
import React, { Component } from "react";
//Install
import JV898P from "../../../Media/Image/001.png";

//Element
class House extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { id: "Web_1YH29X", name: "Web_1YH29X", className: "float-start w-100 position-relative p-0 m-0 mt-4 bg-transparent border-0 h2314u", type: "button" },
            //Element
            React.createElement( "div", { className: "d-flex justify-content-center align-items-center p-2 m-0 zk98z9" },
                //Element
                React.createElement( "img", { alt: "Konutlar için iklimlendirme", title: "Konutlar için iklimlendirme", src: JV898P, width: "24", height: "24" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-absolute a6ox73" },
                //Element
                React.createElement( "span", { className: "float-end p-0 m-0 c8g6r0" }, "Konutlar için iklimlendirme" )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default House;