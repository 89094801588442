//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Copyright extends Component {
    //Element
    render() {
        //Element
        const { Context } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 e7v3f0" }, Context )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Copyright.propTypes = {
    ID      : propTypes.string.isRequired,
    Context : propTypes.string.isRequired
}
//Element
export default Copyright;