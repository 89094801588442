//Install
import React, { Component } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
//Install
import { ReactComponent as AL56P2 } from "../../../Media/Icon/010.svg";
//Install
import { ReactComponent as W434EF } from "../../../Media/Icon/009.svg";
//Install
import Product from "../Product";

//Element
class Blow extends Component {
    //Element
    render() {
        //Element
        SwiperCore.use([ Navigation, Pagination, Autoplay ])
        //Element
        const Slider_Blow = {
            //Element
            spaceBetween  : 12,
            //Element
            slidesPerView : 3,
            //Element
            navigation    : {
                //Element
                prevEl    : "#Web_E01VL6",
                //Element
                nextEl    : "#Web_H2Q30H"
            },
            //Element
            breakpoints   : { 
                //Element
                240       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 0
                },
                //Element
                320       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                480       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                768       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                1024       : {
                    //Element
                    slidesPerView : 3,
                    //Element
                    spaceBetween  : 12
                }
            }
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden g1t38v", ...Slider_Blow },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "11", Title: "Mitsubishi 4 Yöne Üflemeli Kaset Tipi PLA-M / PUZ-M Standart Inverter", Photo: "5256908527.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "12", Title: "Mitsubishi 4 Yöne Üflemeli Kaset Tipi PLA-ZM / PUZ-ZM Power Inverter", Photo: "7379454042.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "13", Title: "Mitsubishi Electric 4 Yöne Üflemeli Kaset Tipi SLZ-M / SUZ-M Inverter", Photo: "2165606033.jpg" } )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 st39m0" },
                //Element
                React.createElement( "button", { id: "Web_E01VL6", name: "Web_E01VL6", className: "d-flex justify-content-center align-items-center p-0 position-relative rounded-circle w2966t", type: "button" },
                    //Element
                    React.createElement( AL56P2, {} )
                ),
                //Element
                React.createElement( "button", { id: "Web_H2Q30H", name: "Web_H2Q30H", className: "d-flex justify-content-center align-items-center p-0 position-relative rounded-circle a255yx", type: "button" },
                    //Element
                    React.createElement( W434EF, {} )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Blow;