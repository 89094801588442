//Install
import React, { Component } from "react";
//Install
import { ReactComponent as H3395U } from "../../Media/Icon/008.svg";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "float-start p-0 m-0" },
            //Element
            React.createElement( "li", { className: "float-start position-relative" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Mitsubishi Electric Klima", href: "/" }, "Mitsubishi Electric Klima" )
            ),
            //Element
            React.createElement( "li", { className: "float-start position-relative" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Kurumsal", href: "/kurumsal" }, "Kurumsal" )
            ),
            //Element
            React.createElement( "li", { className: "float-start position-relative" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Klimalar", href: "/klimalar" }, "Klimalar" ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "a", { className: "float-start", title: "Bireysel Klimalar", href: "/klimalar" },
                            //Element
                            React.createElement( "span", { className: "float-start position-relative ih2l14" }, "Bireysel Klimalar" ),
                            //Element
                            React.createElement( "span", { className: "float-end p-0 m-0 qx3736" },
                                //Element
                                React.createElement( H3395U, {} )
                            )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", title: "Duvar Tipi Klimalar", href: "/klimalar" }, "Duvar Tipi Klimalar" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start position-relative", title: "Döşeme Tipi Klimalar", href: "/klimalar" }, "Döşeme Tipi Klimalar" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start position-relative", title: "Tek Yöne Üflemeli Kaset Tipi Klimalar", href: "/klimalar" }, "Tek Yöne Üflemeli Kaset Tipi Klimalar" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start position-relative", title: "Multi Sistem Klimalar", href: "/klimalar" }, "Multi Sistem Klimalar" )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "a", { className: "float-start", title: "Profesyonel Klimalar", href: "/klimalar" },
                            //Element
                            React.createElement( "span", { className: "float-start position-relative ih2l14" }, "Profesyonel Klimalar" ),
                            //Element
                            React.createElement( "span", { className: "float-end p-0 m-0 qx3736" },
                                //Element
                                React.createElement( H3395U, {} )
                            )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "4 Yöne Üflemeli Kaset Tipi", href: "/klimalar" }, "4 Yöne Üflemeli Kaset Tipi" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Asılı Tavan Tipi Klimalar", href: "/klimalar" }, "Asılı Tavan Tipi Klimalar" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Kanallı Gizli Tavan Tipi Klimalar", href: "/klimalar" }, "Kanallı Gizli Tavan Tipi Klimalar" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Salon Tipi Klimalar", href: "/klimalar" }, "Salon Tipi Klimalar" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Mutfaklar İçin Asılı Tavan Tipi Klimalar", href: "/klimalar" }, "Mutfaklar İçin Asılı Tavan Tipi Klimalar" )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "a", { className: "float-start", title: "VRF Klima Sistemleri", href: "/klimalar" },
                            //Element
                            React.createElement( "span", { className: "float-start position-relative ih2l14" }, "VRF Klima Sistemleri" ),
                            //Element
                            React.createElement( "span", { className: "float-end p-0 m-0 qx3736" },
                                //Element
                                React.createElement( H3395U, {} )
                            )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "City Multi VRF Sistemleri", href: "/klimalar" }, "City Multi VRF Sistemleri" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "City Multi Hybrid VRF Sistemleri", href: "/klimalar" }, "City Multi Hybrid VRF Sistemleri" )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "a", { className: "float-start", title: "Hidronik Sistemler", href: "/klimalar" },
                            //Element
                            React.createElement( "span", { className: "float-start position-relative ih2l14" }, "Hidronik Sistemler" ),
                            //Element
                            React.createElement( "span", { className: "float-end p-0 m-0 qx3736" },
                                //Element
                                React.createElement( H3395U, {} )
                            )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Chiller Sistemleri", href: "/klimalar" }, "Chiller Sistemleri" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Modüler Chiller Sistemleri", href: "/klimalar" }, "Modüler Chiller Sistemleri" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Heat Pump Chiller Sistemleri", href: "/klimalar" }, "Heat Pump Chiller Sistemleri" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Fancoil Sistemleri", href: "/klimalar" }, "Fancoil Sistemleri" )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "a", { className: "float-start", title: "Havalandırma Çözümleri", href: "/klimalar" },
                            //Element
                            React.createElement( "span", { className: "float-start position-relative ih2l14" }, "Havalandırma Çözümleri" ),
                            //Element
                            React.createElement( "span", { className: "float-end p-0 m-0 qx3736" },
                                //Element
                                React.createElement( H3395U, {} )
                            )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Bireysel Havalandırma Çözümleri", href: "/klimalar" }, "Bireysel Havalandırma Çözümleri" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Ticari Havalandırma Çözümleri", href: "/klimalar" }, "Ticari Havalandırma Çözümleri" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Klima Santrali", href: "/klimalar" }, "Klima Santrali" )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "a", { className: "float-start", title: "Paket Tip Klimalar", href: "/klimalar" }, "Paket Tip Klimalar" )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start position-relative" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Referanslar", href: "/referanslar" }, "Referanslar" )
            ),
            //Element
            React.createElement( "li", { className: "float-start position-relative" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Kariyer", href: "/kariyer" }, "Kariyer" )
            ),
            //Element
            React.createElement( "li", { className: "float-start position-relative" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Bize Ulaşın", href: "/bize-ulasin" }, "Bize Ulaşın" )
            )
        )
        //Element
        return (
            <nav className="d-flex w-100 justify-content-center align-items-center p-0 m-0 f61770">
                { Content }
            </nav>
        );
    }
}
//Element
export default Menu;