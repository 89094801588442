//Install
import React, { Component } from "react";
//Install
import { ReactComponent as MU765O } from "../../../Media/Icon/008.svg";
//Install
import propTypes from "prop-types";

//Element
class Ask extends Component {
    //Element
    Show = (e) => {
        //Element
        let Element = e.target.parentElement.nextElementSibling
        //Element
        let Icon    = e.target.parentElement.children[1]
        //Element
        Icon.classList.toggle( "p9wjuz" )
        //Element
        Element.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const { Title, Text } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 f3814d" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-2 bg-transparent border-0 e7877w", type: "button", onClick: this.Show },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 h4831h" },
                    //Element
                    React.createElement( "span", { className: "float-start p-0 m-0 text-truncate x1nl29" }, Title ),
                    //Element
                    React.createElement( "span", { className: "float-start p-0 m-0 wqq462" },
                        //Element
                        React.createElement( MU765O, {} )
                    )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-3 d-none u7627c" },
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-start vk563m" }, Text )
                )
            )
        )
        //Element
        return (
            <div className="col-md-6">
                { Content }
            </div>
        );
    }
}
//Element
Ask.propTypes = {
    ID    : propTypes.number.isRequired,
    Title : propTypes.string.isRequired,
    Text  : propTypes.string.isRequired
}
//Element
export default Ask;