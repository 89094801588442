//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Reference extends Component {
    //Element
    render() {
        //Element
        const { Title, Photo, Target } = this.props
        //Element
        let Source = "https://www.eskaklima.com/upload/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-3 ps-3 pe-3 pb-3 m-0 n454f7", title: Title, href: Target },
            //Element
            React.createElement( "div", { className: "float-start p-0 m-0 s33610" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "110", height: "110" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 xb880z" },
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center u763kh", title: Title }, Title )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Reference.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Reference;