//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Breadcrumb from "../Breadcrumb/Page";

//Element
class Error extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Sayfa Bulunamadı - Mitsubishi Electric Klima : Eskaklima.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Sürdürülebilir Bir Gelecek için Yüksek Enerji Tasarruflu A+++ Serisi Klimalarla Tanışın!"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w531jt" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start e22q94" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u4o95z" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center p750g9" }, "Sayfa Bulunamadı" )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center f1j45n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Sayfa Bulunamadı", Target: "/404" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 mb-4 position-relative o6l4h3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 q98p51" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 q451ef" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center dlh062" }, "Sayfa Bulunamadı..." ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center t3957w" }, "Link hatalı, yayından kaldırılmış veya süresi dolmuş olabilir." )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Error;