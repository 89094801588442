//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as C28D6J } from "../../../Media/Icon/008.svg";
//Install
import Chiller from "../Tab/Chiller";

//Element
class Hydronic extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 g537rv" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 gl6479" },
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 r5163w" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_I43U19", name: "Web_I43U19", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3 a30e10", type: "button", onClick: this.Blow_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Chiller Sistemleri" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:9 },
                    //Element
                    React.createElement( "div", { id: "Web_B64RG0", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0 d-block" },
                        //Element
                        React.createElement( Chiller, {} )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Hydronic;