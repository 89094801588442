//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Breadcrumb from "../Breadcrumb/Page";
//Install
import Header from "../Shortcode/Header";
//Install
import Individual from "../Shortcode/Category/Individual";
//Install
import Professional from "../Shortcode/Category/Professional";
//Install
import VRF from "../Shortcode/Category/VRF";
//Element
import Hydronic from "../Shortcode/Category/Hydronic";
//Install
import Air from "../Shortcode/Category/Air";

//Element
class Category extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Klima ve İklimlendirme Sistemleri - Mitsubishi Electric Klima : Eskaklima.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Sürdürülebilir Bir Gelecek için Yüksek Enerji Tasarruflu A+++ Serisi Klimalarla Tanışın!"
    }
    //Element
    Individuals = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "h804q5" )[0]
        //Element
        let Tab   = document.getElementById( "Web_C97M8M" )
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Tab.classList.toggle( "d-none" )
        //Element
        Panel.scrollIntoView()
    }
    //Element
    Professionals = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "h804q5" )[0]
        //Element
        let Tab   = document.getElementById( "Web_LXQ542" )
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Tab.classList.toggle( "d-none" )
        //Element
        Panel.scrollIntoView()
    }
    //Element
    VRFs = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "h804q5" )[0]
        //Element
        let Tab   = document.getElementById( "Web_V4O94Z" )
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Tab.classList.toggle( "d-none" )
        //Element
        Panel.scrollIntoView()
    }
    //Element
    Hydronic = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "h804q5" )[0]
        //Element
        let Tab   = document.getElementById( "Web_DHI401" )
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Tab.classList.toggle( "d-none" )
        //Element
        Panel.scrollIntoView()
    }
    //Element
    Airs = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "h804q5" )[0]
        //Element
        let Tab   = document.getElementById( "Web_Y16898" )
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Tab.classList.toggle( "d-none" )
        //Element
        Panel.scrollIntoView()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 b659w7" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w531jt" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start e22q94" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u4o95z" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center p750g9" }, "Klimalar" )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center f1j45n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Klimalar", Target: "/klimalar" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mb-3 position-relative o6l4h3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "article", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1 position-relative overflow-hidden v5x9d3", title: "Klimalar", href: "/", onClick: this.Individuals },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative r5ta95" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "Klimalar" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "10 Klima" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "article", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1 position-relative overflow-hidden v5x9d3", title: "Profesyonel Klimalar", href: "/", onClick: this.Professionals },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative h9514u" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "Profesyonel Klimalar" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "10 Klima" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1 position-relative overflow-hidden v5x9d3", title: "VRF Klima Sistemleri", href: "/", onClick: this.VRFs },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative o3164p" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "VRF Klima Sistemleri" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "24 Klima" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 position-relative overflow-hidden v5x9d3", title: "Hidronik Sistemler", href: "/", onClick: this.Hydronic },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative q7j578" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "Hidronik Sistemler" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "1 Klima" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 position-relative overflow-hidden v5x9d3", title: "IT Soğutma Sistemleri", href: "/" },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative p3i5n5" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "IT Soğutma Sistemleri" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "Bulunamadı" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 position-relative overflow-hidden v5x9d3", title: "Isıtma Çözümleri", href: "/" },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative m65434" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "Isıtma Çözümleri" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "Bulunamadı" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 position-relative overflow-hidden v5x9d3", title: "Havalandırma Çözümleri", href: "/", onClick: this.Airs },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative z19w39" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "Havalandırma Çözümleri" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "3 Klima" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 position-relative overflow-hidden v5x9d3", title: "Kontrol Çözümleri", href: "/" },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative oap905" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "Kontrol Çözümleri" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "Bulunamadı" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative overflow-hidden t37yn4" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 position-relative overflow-hidden v5x9d3", title: "El Kurutma Sistemleri", href: "/" },
                                    //Element
                                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-relative i501tu" } ),
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center position-absolute start-0 top-0 p-0 ps-5 m-0 mo943y" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-white gx003r" }, "El Kurutma Sistemleri" ),
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1 text-white d233cz" }, "Bulunamadı" )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center m-0 position-absolute start-0 top-0 d-none h804q5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w898z5" },
                                //Element
                                React.createElement( Header, { ID: "0", Title: "Mitsubishi Electric Klima" } ),
                                //Element
                                React.createElement( "div", { id: "Web_C97M8M", className: "float-start w-100 p-0 m-0 d-none n745hy" },
                                    //Element
                                    React.createElement( Individual, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Web_LXQ542", className: "float-start w-100 p-0 m-0 d-none n745hy" },
                                    //Element
                                    React.createElement( Professional, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Web_V4O94Z", className: "float-start w-100 p-0 m-0 d-none n745hy" },
                                    //Element
                                    React.createElement( VRF, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Web_DHI401", className: "float-start w-100 p-0 m-0 d-none n745hy" },
                                    //Element
                                    React.createElement( Hydronic, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Web_Y16898", className: "float-start w-100 p-0 m-0 d-none n745hy" },
                                    //Element
                                    React.createElement( Air, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Category;