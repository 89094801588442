//Install
import React, { Component } from "react";
//Install
import { ReactComponent as D911YU } from "../../Media/Icon/009.svg";
//Install
import { ReactComponent as NO134T } from "../../Media/Icon/010.svg";

//Element
class Nav extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 ps-5 pe-5 m-0 y5x1g0" },
            //Element
            React.createElement( "button", { id: "Web_H23140", name: "Web_H23140", className: "float-start bg-transparent border-0 position-relative p-0 m-0 x211t5", type: "button" },
                //Element
                React.createElement( NO134T, {} )      
            ),
            //Element
            React.createElement( "button", { id: "Web_H46402", name: "Web_H46402", className: "float-end bg-transparent border-0 position-relative p-0 m-0 x211t5", type: "button" },
                //Element
                React.createElement( D911YU, {} )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Nav;