//Install
import React, { Component } from "react";
//Install
import { ReactComponent as JS556J } from "../../Media/Icon/006.svg";

//Element
class Cookie extends Component {
    //Element
    Create = () => {
        //Element
        document.cookie = "Cookie=OK"
        //Element
        let Panel       = document.getElementsByClassName( "x3287n" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
    }
    //Element
    render() {
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center z35u1h" },
            //Element
            React.createElement( "button", { id: "Web_82GYB6", name: "Web_82GYB6", className: "d-flex justify-content-center align-items-center p-0 m-0 s9wh57", type: "button", onClick: this.Create },
                //Element
                React.createElement( JS556J, {} )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Cookie;