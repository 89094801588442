//Install
import React, { Component } from "react";
//Install
import { ReactComponent as B4LU96 } from "../../../Media/Icon/008.svg";

//Element
class Location extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 x8lb82" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 h7361i" },
                //Element
                React.createElement( "h6", { className: "float-start p-0 m-0 n861vo" }, "Mitsubishi Electric Klima" ),
                //Element
                React.createElement( "span", { className: "float-start p-0 m-0 tr362m" },
                    //Element
                    React.createElement( B4LU96, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-5 m8934e" },
                //Element
                React.createElement( "iframe", { id: "Web_A4TO67", name: "Web_A4TO67", src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3184.771333890061!2d35.27591581529661!3d37.03909817990012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15288919b7435a4b%3A0x7f28d9f3fe18cbc6!2sEska%20Klima%20-%20Mitsubishi%20Electric!5e0!3m2!1str!2str!4v1660910877469!5m2!1str!2str", className: "d-inline-block w-100 h-100", frameBorder: "0", width: "636", height: "318" } )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Location;