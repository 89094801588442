//Install
import React, { Component } from "react";
//Install
import { ReactComponent as JL847S } from "../../Media/Icon/006.svg";
//Install
import { ReactComponent as AC021W } from "../../Media/Icon/010.svg";
//Install
import propTypes from "prop-types";

//Element
class Header extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    Close = () => {
        //Element
        let Panel, Individual, Professional, VRF, Air, Hydronic
        //Element
        Panel        = document.getElementsByClassName( "h804q5" )[0]
        //Element
        Individual   = document.getElementById( "Web_C97M8M" )
        //Element
        Professional = document.getElementById( "Web_LXQ542" )
        //Element
        VRF          = document.getElementById( "Web_V4O94Z" )
        //Element
        Air          = document.getElementById( "Web_DHI401" )
        //Element
        Hydronic     = document.getElementById( "Web_Y16898" )
        //Element
        Panel.classList.add( "d-none" )
        //Element
        Individual.classList.add( "d-none" )
        //Element
        Professional.classList.add( "d-none" )
        //Element
        VRF.classList.add( "d-none" )
        //Element
        Air.classList.add( "d-none" )
        //Element
        Hydronic.classList.add( "d-none" )
    }
    //Element
    render() {
        //Element
        const { Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 ps-5 pe-5 m-0 q3o8y5" },
            //Element
            React.createElement( "div", { className: "float-start w-25 p-0 m-0 f52kl3" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 e968bz" },
                    //Element
                    React.createElement( "button", { id: "Web_Y2U71O", name: "Web_Y2U71O", className: "d-flex justify-content-center align-items-center p-0 m-0 border-0 rounded-circle text-center v26po1", type: "button", onClick: this.Home },
                        //Element
                        React.createElement( AC021W, {} )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-50 p-0 m-0 f52kl3" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 hi362n" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center text-white h4mz49" }, Title )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-25 p-0 m-0 h53008" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 e968bz" },
                    //Element
                    React.createElement( "button", { id: "Web_S7C62N", name: "Web_S7C62N", className: "d-flex justify-content-center align-items-center p-0 m-0 border-0 rounded-circle text-center v188zj", type: "button", onClick: this.Close },
                        //Element
                        React.createElement( JL847S, {} )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Header.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Header;