//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as Z0B36L } from "../../Media/Icon/008.svg";
//Install
import Breadcrumb from "../Breadcrumb/Page";

//Element
class Politics extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Verileri Koruma Politikaları - Mitsubishi Electric Klima : Eskaklima.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Sürdürülebilir Bir Gelecek için Yüksek Enerji Tasarruflu A+++ Serisi Klimalarla Tanışın!"
    }
    //Element
    Individual = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "http://media.eskaklima.com/kisisel-verilerin-korunmasi.pdf"
    }
    //Element
    Cookie = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "http://media.eskaklima.com/cerez-politikalari.pdf"
    }
    //Element
    Form = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "http://media.eskaklima.com/ilgili-kisi-basvuru-formu.pdf"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w531jt" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start e22q94" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u4o95z" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center p750g9" }, "Verileri Koruma Politikaları" )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center f1j45n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Verileri Koruma Politikaları", Target: "/bireysel-verileri-koruma" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 mb-4 position-relative o6l4h3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 q98p51" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 h-auto p-5 pt-0 pb-0 m-0 q451ef" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 dlh062" }, "Bireysel Verileri Koruma Politikaları" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 t3957w" }, "Gerekli bilgiler için lütfen burada bulunan dokümanlara bakınız." )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-5 pt-0 pb-0 m-0 mq873u" },
                                //Element
                                React.createElement( "button", { id: "Web_ZN8X12", name: "Web_ZN8X12", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 w780q7", type: "button", onClick: this.Individual },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 t6315y" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-75 p-0 m-0 text-start wi264q" }, "Bireysel Verileri Koruma Politikaları" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex w-25 justify-content-end align-items-center p-0 m-0 text-start r64em2" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_S76KH9", name: "Web_S76KH9", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 w780q7", type: "button", onClick: this.Cookie },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 t6315y" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-75 p-0 m-0 text-start wi264q" }, "Çerez Politikaları" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex w-25 justify-content-end align-items-center p-0 m-0 text-start r64em2" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_H44B2V", name: "Web_H44B2V", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 w780q7", type: "button", onClick: this.Form },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 t6315y" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-75 p-0 m-0 text-start wi264q" }, "İlgili Kişiler için Başvuru Formu" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex w-25 justify-content-end align-items-center p-0 m-0 text-start r64em2" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Politics;