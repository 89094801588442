//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Breadcrumb from "../Breadcrumb/Page";

//Element
class Reference extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Kimlere Hizmet Sunuldu? - Mitsubishi Electric Klima : Eskaklima.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Sürdürülebilir Bir Gelecek için Yüksek Enerji Tasarruflu A+++ Serisi Klimalarla Tanışın!"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w531jt" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start e22q94" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u4o95z" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center p750g9" }, "Kimlere Hizmet Sunuldu?" )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center f1j45n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Kimlere Hizmet Sunuldu?", Target: "/referanslar" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 mb-4 position-relative o6l4h3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Arbel Bakliyat Hububat A.Ş.", title: "Arbel Bakliyat Hububat A.Ş.", src: "https://www.eskaklima.com/upload/6224828365.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Arbel Bakliyat" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Radisson Blu Hotels", title: "Radisson Blu Hotels", src: "https://www.eskaklima.com/upload/5204185517.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Radisson Blu Hotel" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Novotel", title: "Novotel", src: "https://www.eskaklima.com/upload/1422497160.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Novotel" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "The Green Park Hotel", title: "The Green Park Hotel", src: "https://www.eskaklima.com/upload/4163686224.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "The Green Park Hotel" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Kursan Ambalaj A.Ş", title: "Kursan Ambalaj A.Ş", src: "https://www.eskaklima.com/upload/3630838402.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Kursan Ambalaj A.Ş" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Hasan Usta Kebap", title: "Hasan Usta Kebap", src: "https://www.eskaklima.com/upload/9232814759.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Hasan Usta Kebap" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Ciğerci Hadi", title: "Ciğerci Hadi", src: "https://www.eskaklima.com/upload/3859058853.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Ciğerci Hadi" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Şırdancı Bedo", title: "Şırdancı Bedo", src: "https://www.eskaklima.com/upload/5979536466.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Şırdancı Bedo" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Yol İş Holiday", title: "Yol İş Holiday", src: "https://www.eskaklima.com/upload/0170588576.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Yol İş Holiday" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Erten Otel", title: "Erten Otel", src: "https://www.eskaklima.com/upload/3265101116.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Erten Otel" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Soulmate Coffee", title: "Soulmate Coffee", src: "https://www.eskaklima.com/upload/2865242148.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Soulmate Coffee" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Hatay Altınözü Bel.", title: "Hatay Altınözü Bel.", src: "https://www.eskaklima.com/upload/2305040971.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Hatay Altınözü Bel." )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Pres Makina A.Ş.", title: "Pres Makina A.Ş.", src: "https://www.eskaklima.com/upload/0193798622.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Pres Makina A.Ş." )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Mersin Özel Su Hastanesi", title: "Mersin Özel Su Hastanesi", src: "https://www.eskaklima.com/upload/2293176124.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Mersin Özel Su Hastanesi" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Ekrem Tır", title: "Ekrem Tır", src: "https://www.eskaklima.com/upload/5385960120.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Ekrem Tır" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Savon Hotel", title: "Savon Hotel", src: "https://www.eskaklima.com/upload/5178327029.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Savon Hotel" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Tarbus Hotel", title: "Tarbus Hotel", src: "https://www.eskaklima.com/upload/2911023249.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Tarbus Hotel" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Akım Elektronik", title: "Akım Elektronik", src: "https://www.eskaklima.com/upload/0841206754.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Akım Elektronik" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Çetinkaya Giyim A.Ş.", title: "Çetinkaya Giyim A.Ş.", src: "https://www.eskaklima.com/upload/0062734545.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Çetinkaya Giyim A.Ş." )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "DeFacto", title: "DeFacto", src: "https://www.eskaklima.com/upload/1928845858.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "DeFacto" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "İncirlik Hava Üssü", title: "İncirlik Hava Üssü", src: "https://www.eskaklima.com/upload/6817390949.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "İncirlik Hava Üssü" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Yapı ve Kredi Bankası A.Ş.", title: "Yapı ve Kredi Bankası A.Ş.", src: "https://www.eskaklima.com/upload/5677399822.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Yapı ve Kredi Bankası A.Ş." )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Doğa Koleji", title: "Doğa Koleji", src: "https://www.eskaklima.com/upload/3409053421.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Doğa Koleji" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Saltcam", title: "Saltcam", src: "https://www.eskaklima.com/upload/8406018161.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Saltcam" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Sanko Holding", title: "Sanko Holding", src: "https://www.eskaklima.com/upload/7654430547.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Sanko Holding" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Özel Doğuş Hastanesi", title: "Özel Doğuş Hastanesi", src: "https://www.eskaklima.com/upload/9405822690.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Özel Doğuş Hastanesi" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Tat Kimya A.Ş.", title: "Tat Kimya A.Ş.", src: "https://www.eskaklima.com/upload/6509600504.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Tat Kimya A.Ş." )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Yenişehir Özel Tıp Merkezi", title: "Yenişehir Özel Tıp Merkezi", src: "https://www.eskaklima.com/upload/5946900409.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Yenişehir Özel Tıp Merkezi" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Çukurova Üniversitesi", title: "Çukurova Üniversitesi", src: "https://www.eskaklima.com/upload/1716199367.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Çukurova Üniversitesi" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Ender Giyim A.Ş.", title: "Ender Giyim A.Ş.", src: "https://www.eskaklima.com/upload/3291357118.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Ender Giyim A.Ş." )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "ESC Mühendislik", title: "ESC Mühendislik", src: "https://www.eskaklima.com/upload/3668468099.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "ESC Mühendislik" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Fizica Özel Sağlık Hizmetleri A.Ş.", title: "Fizica Özel Sağlık Hizmetleri A.Ş.", src: "https://www.eskaklima.com/upload/3587345504.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Fizica Özel Sağlık Hizmetleri A.Ş." )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Mondi Tire Kağıt ve Ambalaj San. A.Ş.", title: "Mondi Tire Kağıt ve Ambalaj San. A.Ş.", src: "https://www.eskaklima.com/upload/9232420161.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Mondi Tire Kağıt ve Ambalaj San. A.Ş." )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Adana Atatürk Evi", title: "Adana Atatürk Evi", src: "https://www.eskaklima.com/upload/4979867087.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Adana Atatürk Evi" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Adana Müze Müdürlüğü", title: "Adana Müze Müdürlüğü", src: "https://www.eskaklima.com/upload/4979867087.png", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Adana Müze Müdürlüğü" )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 j111qi" },
                                //Element
                                React.createElement( "div", { className: "float-start p-0 m-0 h5162l" },
                                    //Element
                                    React.createElement( "img", { alt: "Yemek Sepeti A.Ş.", title: "Yemek Sepeti A.Ş.", src: "https://www.eskaklima.com/upload/8913755327.jpg", width: "96", height: "96" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 text-center nf25l0" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate x0j96v" }, "Yemek Sepeti A.Ş." )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Reference;