//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { useParams } from "react-router-dom";
//Install
import Axios from "axios";

//Element
function Features(){
    //Element
    const { ID } = useParams()
    //Element
    const [ D4A49B, G649EO ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://www.eskaklima.com/API/XCQGG22S1I", {
                //Element
                params : { WY0FMP : ID }
            })
            //Element
            G649EO( Public.data )
        }
        //Element
        Fetch()
    }, [])
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 pt-2 m-0 mt-4 i03i16" },
        //Element
        React.createElement( Row, {},
            //Element
            D4A49B.map( (Data) => {
                //Element
                return React.createElement( Col, { key: Data.TQJT5Y, md:4 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 ht88c1" },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 mt-3 text-capitalize text-truncate a3158z" }, Data.T9PQ2I )
                    )
                )
            })
        )
    )
    //Element
    return(
        <div className="float-start w-100">
            { Content }
        </div>
    )
}
//Element
export default Features;
