//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Category extends Component {
    //Element
    render() {
        //Element
        const { Title, Summary, Photo, Value, Target } = this.props
        //Element
        let Source
        //Element
        Source = "https://www.eskaklima.com/upload/"
        //Element
        let Image
        //Element
        Image = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 m444ho", title: Title, href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k3039j" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "636", height: "318" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 ps-5 pe-5 pt-4 bg-white p594hm" },
                //Element
                React.createElement( "h3", { className: "float-start p-0 m-0 n9v17b" }, Title ),
                //Element
                React.createElement( "span", { className: "float-end p-0 m-0 hx030l" }, Value ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 mb-2 overflow-hidden w9z2e0" }, Summary )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Category.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired,
    Value   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Category;