//Install
import React, { Component } from "react";
//Install
import { ReactComponent as F03KX6 } from "../../Media/Icon/008.svg";
//Install
import propTypes from "prop-types";

//Element
class Page extends Component {
    //Element
    render() {
        //Element
        const { Title, Target } = this.props
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-start align-items-center position-relative p-0 m-0 mt-2 z272kl", itemScope: "itemscope", itemType: "https://schema.org/BreadcrumbList" },
            //Element
            React.createElement( "li", { className: "float-start", itemProp: "itemListElement", itemScope: "itemscope", itemType: "https://schema.org/ListItem" },
                //Element
                React.createElement( "a", { className: "float-start", itemProp: "item", title: "Mitsubishi Klima", href: "/" },
                    //Element
                    React.createElement( "span", { className: "float-start", itemProp: "name" }, "Mitsubishi Electric Klima" )
                ),
                //Element
                React.createElement( "meta", { itemProp: "position", content: "1" } )
            ),
            React.createElement( "li", { className: "float-start"},
                //Element
                React.createElement( "span", { className: "float-start p-0 m-0 ms-2 me-2 zoq741" },
                    //Element
                    React.createElement( F03KX6, {} )
                )
            ),
            React.createElement( "li", { className: "float-start", itemProp: "itemListElement", itemScope: "itemscope", itemType: "https://schema.org/ListItem" },
                //Element
                React.createElement( "a", { className: "float-start", itemProp: "item", title: Title, href: Target },
                    //Element
                    React.createElement( "span", { className: "float-start", itemProp: "name" }, Title )
                ),
                //Element
                React.createElement( "meta", { itemProp: "position", content: "2" } )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Page.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Page;