//Install
import React, { Component } from "react";

//Element
class Keyword extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center s59k83" },
            //Element
            React.createElement( "ul", { className: "float-start p-0 m-0 pir95i" },
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "a", { className: "float-start p-0 ps-3 pe-3", title: "Mitsubishi Electric Klima", href: "/" }, "Mitsubishi Electric Klima" )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "a", { className: "float-start p-0 ps-3 pe-3", title: "Mitsubishi A+++ Klima", href: "/urun/mitsubishi-kirigamine-zen-msz-ef-klima/4" }, "Mitsubishi A+++ Klima" )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "a", { className: "float-start p-0 ps-3 pe-3", title: "Mitsubishi Klima 12000 A++", href: "/urun/mitsubishi-legendera-msz-ln-klima/1" }, "Mitsubishi Klima 12000 A++" )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "a", { className: "float-start p-0 ps-3 pe-3", title: "Mitsubishi Klima 18000 A+++", href: "/urun/mitsubishi-envirome-msz-ap-klima/2/" }, "Mitsubishi Klima 18000 A+++" )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "a", { className: "float-start p-0 ps-3 pe-3", title: "Mitsubishi Klima 24000 A++", href: "/urun/mitsubishi-salon-tipi-psa-rp-puhz-p-standart-inverter/21" }, "Mitsubishi Klima 24000 A++" )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "a", { className: "float-start p-0 ps-3 pe-3", title: "Mitsubishi Klima 18000", href: "/urun/mitsubishi-hareme-msz-hr-klima/3" }, "Mitsubishi Klima 18000" )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Keyword;