//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Similiar extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mb-5 v013zp" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 r44t4q" },
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 d97g9t" }, "Bunlara Bakın" )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d261wo" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 s827p2", title: "Legendera Klima", href: "/urun/mitsubishi-legendera-msz-ln-klima/1" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 i1316u" },
                                //Element
                                React.createElement( "img", { alt: "Legendera Klima", title: "Legendera Klima", src: "https://www.eskaklima.com/upload/2891861073.jpg", width: "306", height: "153" } )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 y69i7d" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center w1811y" }, "Legendera Klima" )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 s827p2", title: "EnviroME Klima", href: "/urun/mitsubishi-envirome-msz-ap-klima/2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 i1316u" },
                                //Element
                                React.createElement( "img", { alt: "EnviroME Klima", title: "EnviroME Klima", src: "https://www.eskaklima.com/upload/7937772245.jpg", width: "306", height: "153" } )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 y69i7d" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center w1811y" }, "EnviroME Klima" )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 s827p2", title: "HareME Klima", href: "/urun/mitsubishi-hareme-msz-hr-klima/3" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 i1316u" },
                                //Element
                                React.createElement( "img", { alt: "HareME Klima", title: "HareME Klima", src: "https://www.eskaklima.com/upload/2078294575.jpg", width: "306", height: "153" } )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 y69i7d" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center w1811y" }, "HareME Klima" )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 s827p2", title: "Kirigamine Zen Klima", href: "/urun/mitsubishi-kirigamine-zen-msz-ef-klima/4" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 i1316u" },
                                //Element
                                React.createElement( "img", { alt: "Kirigamine Zen Klima", title: "Kirigamine Zen Klima", src: "https://www.eskaklima.com/upload/3984444313.jpg", width: "306", height: "153" } )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 y69i7d" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center w1811y" }, "Kirigamine Zen Klima" )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Similiar;