//Install
import React from "react";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "swiper/css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import About from "./Component/Page/About";
//Install
import Media from "./Component/Page/Media";
//Install
import Category from "./Component/Page/Category";
//Install
import Product from "./Component/Page/Product";
//Install
import Reference from "./Component/Page/Reference";
//Install
import Career from "./Component/Page/Career";
//Install
import Question from "./Component/Page/Question";
//Install
import Support from "./Component/Page/Support";
//Install
import Contact from "./Component/Page/Contact";
//Install
import Politics from "./Component/Page/Politics";
//Install
import Error from "./Component/Page/Error";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/kurumsal" element={ <About /> }></Route>
          <Route path="/medya" element={ <Media /> }></Route>
          <Route path="/klimalar/" element={ <Category /> }></Route>
          <Route path="/urun/:Title/:ID" element={ <Product /> }></Route>
          <Route path="/referanslar" element={ <Reference /> }></Route>
          <Route path="/kariyer" element={ <Career /> }></Route>
          <Route path="/sikca-sorulan-sorular" element={ <Question /> }></Route>
          <Route path="/destek-talebi" element={ <Support /> }></Route>
          <Route path="/bize-ulasin" element={ <Contact /> }></Route>
          <Route path="/bireysel-verileri-koruma" element={ <Politics /> }></Route>
          <Route path="/urun/legendera-serisi-1" element={ <Navigate to="/urun/mitsubishi-legendera-msz-ln-klima/1" /> }></Route>
          <Route path="/urun/envirome-serisi-2" element={ <Navigate to="/urun/mitsubishi-envirome-msz-ap-klima/2" /> }></Route>
          <Route path="/urun/hareme-serisi-3" element={ <Navigate to="/urun/mitsubishi-hareme-msz-hr-klima/3" /> }></Route>
          <Route path="/urun/kirigamine-zen-serisi-4" element={ <Navigate to="/urun/mitsubishi-kirigamine-zen-msz-ef-klima/4" /> }></Route>
          <Route path="*" element={ <Error /> }></Route>
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;
