//Install
import React, { Component } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
//Install
import { ReactComponent as AL56P2 } from "../../../Media/Icon/010.svg";
//Install
import { ReactComponent as W434EF } from "../../../Media/Icon/009.svg";
//Install
import Product from "../Product";

//Element
class Solution extends Component {
    //Element
    render() {
        //Element
        SwiperCore.use([ Navigation, Pagination, Autoplay ])
        //Element
        const Slider_Solution = {
            //Element
            spaceBetween  : 12,
            //Element
            slidesPerView : 3,
            //Element
            navigation    : {
                //Element
                prevEl    : "#Web_HY64F0",
                //Element
                nextEl    : "#Web_MF679E"
            },
            //Element
            breakpoints   : { 
                //Element
                240       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 0
                },
                //Element
                320       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                480       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                768       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                1024       : {
                    //Element
                    slidesPerView : 3,
                    //Element
                    spaceBetween  : 12
                }
            }
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden g1t38v", ...Slider_Solution },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "76", Title: "Mitsubishi Electric VL-50(E)S2-E", Photo: "1408322204.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "77", Title: "Mitsubishi Electric VL-100(E)U5-E", Photo: "8849193625.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "78", Title: "Mitsubishi Electric VL-250/350/500CZPVU-R/L-E", Photo: "1741446465.jpg" } )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 st39m0" },
                //Element
                React.createElement( "button", { id: "Web_HY64F0", name: "Web_HY64F0", className: "d-flex justify-content-center align-items-center p-0 position-relative rounded-circle w2966t", type: "button" },
                    //Element
                    React.createElement( AL56P2, {} )
                ),
                //Element
                React.createElement( "button", { id: "Web_MF679E", name: "Web_MF679E", className: "d-flex justify-content-center align-items-center p-0 position-relative rounded-circle a255yx", type: "button" },
                    //Element
                    React.createElement( W434EF, {} )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Solution;