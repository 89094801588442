//Install
import React, { Component } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
//Install
import { ReactComponent as B03306 } from "../../Media/Icon/007.svg";

//Element
class Slider extends Component {
    //Element
    Redirect = () => {
        //Element
        window.location.href = "/bize-ulasin"
    }
    //Element
    render() {
        //Element
        SwiperCore.use([ Navigation, Pagination, Autoplay ])
        //Element
        const Contact = {
            //Element
            spaceBetween  : 0,
            //Element
            slidesPerView : 1,
            //Element
            autoplay      : {
                //Element
                delay     : 5000
            },
            //Element
            loop          : true
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden e775b0", ...Contact },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "button", { id: "Web_Z572N2", name: "Web_Z572N2", className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 bg-transparent border-0 position-relative m6i04i", type: "button", onClick: this.Redirect },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 k27wk6" },
                            //Element
                            React.createElement( B03306, {} )
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 position-relative i123i7" }, "0322 248 88 99" )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Slider;