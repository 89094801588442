//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as PNK7XM } from "../Media/Icon/001.svg";
//Install
import { ReactComponent as XJ463D } from "../Media/Icon/002.svg";
//Install
import { ReactComponent as DQ21L9 } from "../Media/Icon/003.svg";
//Install
import { ReactComponent as D1MI6M } from "../Media/Icon/004.svg";
//Install
import { ReactComponent as HI712Q } from "../Media/Icon/005.svg";
//Install
import Keyword from "./Footer/Keyword";
//Install
import Menu from "./Footer/Menu";
//Install
import Social from "./Footer/Social";
//Install
import Submenu from "./Footer/Submenu";
//Install
import Copyright from "./Footer/Copyright";
//Install
import Bottom from "./Mobile/Bottom";

//Element
class Footer extends Component {
    //Element
    Location = () => {
        //Element
        window.location.href = "https://bit.ly/3GYoy8F"
    }
    //Element
    Scroll = () => {
        //Element
        let Element = document.documentElement
        //Element
        Element.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 z1e890" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 obbx5v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Keyword, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 db75du" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Menu, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 mt-5 position-relative a2kp8i" },
                                    //Element
                                    React.createElement( "div", { className: "d-flex justify-content-center align-items-center p-0 m-0 rounded-circle o6v2y8" },
                                        //Element
                                        React.createElement( D1MI6M, {} )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 iej09i" },
                                        //Element
                                        React.createElement( "h6", { className: "float-end w-100 p-0 m-0 text-end lh-1 f630xx" }, "Whatsapp Destek Hattı" ),
                                        //Element
                                        React.createElement( "button", { id: "Web_DAR0RE", name: "Web_DAR0RE", className: "float-end w-100 p-0 m-0 mt-2 bg-transparent border-0 text-end f6be59", type: "button" }, "0532 681 29 69" ),
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 mt-5 position-relative a2kp8i" },
                                    //Element
                                    React.createElement( "div", { className: "d-flex justify-content-center align-items-center p-0 m-0 rounded-circle w9b4zl" },
                                        //Element
                                        React.createElement( HI712Q, {} )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 iej09i" },
                                        //Element
                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-end lh-1 f630xx" }, "Çağrı Destek Merkez" ),
                                        //Element
                                        React.createElement( "button", { id: "Web_V2Z7S7", name: "Web_V2Z7S7", className: "float-end w-100 p-0 m-0 mt-2 bg-transparent border-0 text-end f6be59", type: "button" }, "0322 248 88 99" )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 x4vs5d" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center e56wqh" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-25 justify-content-start align-items-center" },
                                    //Element
                                    React.createElement( "button", { id: "Web_C98QRC", name: "Web_C98QRC", className: "d-flex justify-content-center align-items-center p-0 m-0 bg-transparent yr22aj", title: "Haritada Bul", type: "button", onClick: this.Location },
                                        //Element
                                        React.createElement( XJ463D, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-50 justify-content-center align-items-center" },
                                    //Element
                                    React.createElement( Social, { ID: "0", Facebook: "https://www.facebook.com/eskaklima", Instagram: "https://www.instagram.com/", Youtube: "https://www.youtube.com/" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-25 justify-content-end align-items-center" },
                                    //Element
                                    React.createElement( "button", { id: "Web_CN58DH", name: "Web_CN58DH", className: "d-flex justify-content-center align-items-center p-0 m-0 bg-transparent z4mie7", title: "Yukarı", type: "button", onClick: this.Scroll },
                                        //Element
                                        React.createElement( PNK7XM, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 r4z6vm" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Copyright, { ID: "0", Context: "Telif Hakkı © 2022 Eskaklima.com İklimlendirme Sistemleri Tüm Hakları Saklıdır" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center" },
                                //Element
                                React.createElement( Submenu, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-fixed start-0 bottom-0 z9e62w" },
                //Element
                React.createElement( Bottom, {} )
            )
        )
        //Element
        return (
            <footer className="float-start w-100">
                { Content }
            </footer>
        );
    }
}
//Element
export default Footer;