//Install
import React, { Component } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
//Install
import { ReactComponent as AL56P2 } from "../../../Media/Icon/010.svg";
//Install
import { ReactComponent as W434EF } from "../../../Media/Icon/009.svg";
//Install
import Product from "../Product";

//Element
class VRF extends Component {
    //Element
    render() {
        //Element
        SwiperCore.use([ Navigation, Pagination, Autoplay ])
        //Element
        const Slider_VRF = {
            //Element
            spaceBetween  : 12,
            //Element
            slidesPerView : 3,
            //Element
            navigation    : {
                //Element
                prevEl    : "#Web_T4X065",
                //Element
                nextEl    : "#Web_D2893I"
            },
            //Element
            breakpoints   : { 
                //Element
                240       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 0
                },
                //Element
                320       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                480       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                768       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                1024       : {
                    //Element
                    slidesPerView : 3,
                    //Element
                    spaceBetween  : 12
                }
            }
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden g1t38v", ...Slider_VRF },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "23", Title: "Mitsubishi Heat Pump PUHY-EP-Y(S)NW-A1", Photo: "1732772375.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "24", Title: "Mitsubishi Hava Soğutmalı Heat Pump PUHY-P-Y(S)HA", Photo: "5578623161.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "25", Title: "Mitsubishi Hava Soğutmalı Heat Pump PUHY-P-Y(S)NW-A1", Photo: "4488901843.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "26", Title: "Mitsubishi Hava Soğutmalı Heat Pump PUMY-P", Photo: "2435053678.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "27", Title: "Mitsubishi Hava Soğutmalı Heat Pump PUMY-P-YBM", Photo: "6498790855.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "28", Title: "Mitsubishi Hava Soğutmalı Heat Pump PUMY-SP", Photo: "7492462119.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "29", Title: "Mitsubishi Hava Soğutmalı Heat Recovery PURY-EP-Y(S)NW-A1 Next Generation", Photo: "5896329071.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "30", Title: "Mitsubishi Hava Soğutmalı Heat Recovery PURY-P-Y(S)NW-A1 Next Generation", Photo: "4414337598.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "31", Title: "Mitsubishi Su Soğutmalı Heat Pump PQHY-P-YLM", Photo: "0689716196.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "32", Title: "Mitsubishi Su Soğutmalı Heat Recovery PQRY-P-YLM", Photo: "4090722950.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "33", Title: "Mitsubishi Zubadan PUHY-HP-Y(S)HM", Photo: "9888054267.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "34", Title: "Mitsubishi Replace Dış Üniteler PUHY-RP-Y(S)JM", Photo: "3053487378.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "35", Title: "Mitsubishi Replace Dış Üniteler PURY-RP-Y(S)JM", Photo: "3118587914.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "36", Title: "Mitsubishi Özel Kaplamalı Dış Üniteler PUHY-EP-Y(S)NW-A1-BS", Photo: "4178884020.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "37", Title: "Mitsubishi Özel Kaplamalı Dış Üniteler PUHY-HP-Y(S)HM-A-BS", Photo: "6091548517.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "38", Title: "Mitsubishi Özel Kaplamalı Dış Üniteler PUHY-P-Y(S)HA-BS", Photo: "4722125517.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "39", Title: "Mitsubishi Özel Kaplamalı Dış Üniteler PUHY-P-Y(S)NW-A1-BS", Photo: "8524435561.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "40", Title: "Mitsubishi Özel Kaplamalı Dış Üniteler PUMY-P-BS", Photo: "9606229469.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "41", Title: "Mitsubishi Özel Kaplamalı Dış Üniteler PUMY-SP-BS", Photo: "1565066331.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "42", Title: "Mitsubishi Özel Kaplamalı Dış Üniteler PURY-EP-Y(S)NW-A1-BS", Photo: "1252419638.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "43", Title: "Mitsubishi Özel Kaplamalı Dış Üniteler PURY-P-Y(S)NW-A1-BS", Photo: "1930379316.jpg" } )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 st39m0" },
                //Element
                React.createElement( "button", { id: "Web_T4X065", name: "Web_T4X065", className: "d-flex justify-content-center align-items-center p-0 position-relative rounded-circle w2966t", type: "button" },
                    //Element
                    React.createElement( AL56P2, {} )
                ),
                //Element
                React.createElement( "button", { id: "Web_D2893I", name: "Web_D2893I", className: "d-flex justify-content-center align-items-center p-0 position-relative rounded-circle a255yx", type: "button" },
                    //Element
                    React.createElement( W434EF, {} )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default VRF;