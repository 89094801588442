//Install
import React, { Component } from "react";

//Element
class Location extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 te668i" },
            //Element
            React.createElement( "iframe", { id: "Web_U1ED75", name: "Web_U1ED75", className: "float-start w-100 h-100 p-0 m-0", src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3184.771333890061!2d35.27591581529661!3d37.03909817990012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15288919b7435a4b%3A0x7f28d9f3fe18cbc6!2sEska%20Klima%20-%20Mitsubishi%20Electric!5e0!3m2!1str!2str!4v1660910877469!5m2!1str!2str", width: "600", height: "450", allowFullScreen: "", loading: "lazy", referrerPolicy: "no-referrer-when-downgrade" } )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Location;