//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Product extends Component {
    //Element
    render() {
        //Element
        const { Title, Photo, Target } = this.props
        //Element
        let Source = "https://www.eskaklima.com/upload/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 ogi422", title: Title, href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 ub6472" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "416", height: "208" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0" },
                //Element
                React.createElement( "h5", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 text-center ss1j85" }, Title )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Product.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Product;