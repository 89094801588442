//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { useParams } from "react-router-dom";
//Install
import Axios from "axios";
//Install
import Ask from "./Ask";

//Element
function Answer(){
    //Element
    const { ID } = useParams()
    //Element
    const [ SL0669, M5440O ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://www.eskaklima.com/API/Z36PX9PIFT", {
                //Element
                params : { TQAQ9U : ID }
            })
            //Element
            M5440O( Public.data )
        }
        //Element
        Fetch()
    }, [])
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-5 b40pk8" },
        //Element
        React.createElement( Row, {},
            //Element
            SL0669.map( (Data) => {
                //Element
                return React.createElement( Ask, { key: Data.P1IZO8, ID: Data.P1IZO8, Title: Data.EHL64H, Text: Data.DM1U4L } )
            })
        )
    )
    //Element
    return(
        <div className="float-start w-100">
            { Content }
        </div>
    )
}
//Element
export default Answer;