//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as M4T728 } from "../../Media/Icon/006.svg";
//Install
import { ReactComponent as KY8659 } from "../../Media/Icon/007.svg";
//Install
import { ReactComponent as AJK559 } from "../../Media/Icon/015.svg";

//Element
class Top extends Component {
    //Element
    Create = () => {
        //Element
        document.cookie = "Cookie=OK"
        //Element
        let Panel       = document.getElementsByClassName( "n7j1g8" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
    }
    //Element
    Hamburger = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Menu, Close, Navigation, Body
        //Element
        Menu       = document.getElementById( "Web_QU140D" )
        //Element
        Close      = document.getElementById( "Web_P305NU" )
        //Element
        Navigation = document.getElementsByClassName( "k729de" )[0]
        //Element
        Menu.classList.toggle( "d-none" )
        //Element
        Close.classList.toggle( "d-none" )
        //Element
        Navigation.classList.toggle( "d-none" )
    }
    //Element
    Home = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "/"
    }
    //Element
    Call = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "tel:+903222488899"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 vy032c" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n7j1g8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "button", { id: "Web_ZW8287", name: "Web_ZW8287", className: "float-start w-100 p-0 m-0 bg-transparent border-0 oq9210", onClick: this.Create },
                                //Element
                                React.createElement( "span", { className: "float-start p-0 m-0 text-truncate text-start h50iu1" }, "Bu sitenin daha iyi, daha hızlı ve güvenli çalışması için çerez kullanılmaktadır." ),
                                //Element
                                React.createElement( "div", { className: "d-flex justify-content-end align-items-center p-0 m-0 bg-transparent border-0 tz9913" },
                                    //Element
                                    React.createElement( M4T728, {} )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d3c272" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-25 p-0 m-0 do615p" },
                                //Element
                                React.createElement( "button", { id: "Web_QU140D", name: "Web_QU140D", className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 u8018v", type: "button", onClick: this.Hamburger },
                                    //Element
                                    React.createElement( AJK559, {} )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_P305NU", name: "Web_P305NU", className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 d-none i52ud3", type: "button", onClick: this.Hamburger },
                                    //Element
                                    React.createElement( M4T728, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-50 p-0 m-0 do615p" },
                                //Element
                                React.createElement( "button", { id: "Web_U03256", name: "Web_U03256", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 text-white b03th6", type: "button", onClick: this.Home }, "Eskaklima.com" )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-25 p-0 m-0 do615p" },
                                //Element
                                React.createElement( "button", { id: "Web_C0480X", name: "Web_C0480X", className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 bg-transparent border-0 a0d503", type: "button", onClick: this.Call },
                                    //Element
                                    React.createElement( KY8659, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Top;