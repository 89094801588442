//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Breadcrumb from "../Breadcrumb/Page";

//Element
class About extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Hakkımızda - Mitsubishi Electric Klima : Eskaklima.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Sürdürülebilir Bir Gelecek için Yüksek Enerji Tasarruflu A+++ Serisi Klimalarla Tanışın!"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w531jt" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start e22q94" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u4o95z" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center p750g9" }, "Vizyonumuzdan aldığımız ilham bize yön gösterir" )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center f1j45n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Kurumsal", Target: "/kurumsal" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative o6l4h3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 mt-3 q98p51" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-5 pt-0 pb-0 pe-0 ps-4 m-0 mt-3 i02f1u" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 iy941h" }, "Changes For The Better" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 oi4351" }, "1995 Yılında Kurulan Eska İklimlendirme Sistemleri San. Tic. LTD. 28 Senelik Bilgi, Birikimi ile Mitsubishi Electric'e Bağlı Yetkili Servis Sözleşmeleri Gereği Tek Bir Çatı Altında Birleşerek;" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 oi4351" }, "2005 Yılında Bugün Kadar Isıtma, Soğutma, Havalandırma ve Klima Sektöründe Taahhüt Hizmetleri, Malzeme Temini ve Satış Sonrası Hizmet Konularında Faaliyet Göstermeye Başlamıştır." ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 oi4351" }, "Günümüzde İklimlendirme Sistemleri Kişiler veya Kurumlar için Konfor Olmaktan Çıkarak Gerekli Bir İhtiyaç Olmuştur." ),
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-3 iy941h" }, "Gelecek için. Birlikte" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 oi4351" }, "Son Günlerde Çoğalan Bu Talebe Karşı Hizmet Olarak İyi Şekilde Cevap Veren Firmalardan Biri Olduğumuzu Düşünüyoruz." ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 oi4351" }, "Teknik Bilgi ve Beceri Bakımından Kendini Sürekli Geliştiren ve İşini Mükemmel Yapabilmek Adına İşine Her Daim Yatırım Yapan Eska iklimlendirme Sistemleri;" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 oi4351" }, "Merkezi Konfor Klimaları, Hassas Kontrollü Klimalar, Havalandırma Sistemleri Değişken VRF-VRV Sistemleri, Isı Geri Kazanım Cihazları, Soğutma Grupları Mekanik Tesisat Konularında Hizmet Sunmaktadır." ),
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-3 iy941h" }, "Toplum için. Birlikte" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 oi4351" }, "Kuruluşundan Bugüne Hizmet Sunduğu Bölümlerde Binlerce Projeyi Başarı ile Tamamlayan Eska İklimlendirme, Yüksek Kaliteli Hizmet Anlayışıyla Müşteri Memnuniyetini İlke Edinmiştir." ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 oi4351" }, 
                                    //Element
                                    "Eska Klima ",
                                    //Element
                                    React.createElement( "u", {}, "Türk Standartları Enstitüsü" ),
                                    //Element
                                    " Hizmet Yeterlilik Belgelerine Sahiptir."
                                    //Element
                                    //Element
                                )
                                
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 nv5q43" },
                                        //Element
                                        React.createElement( "img", { alt: "Eska Klima", title: "Eska Klima", src: "https://www.eskaklima.com/upload/9589954605.jpg", width: "416", height: "312" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 nv5q43" },
                                        //Element
                                        React.createElement( "img", { alt: "Eska Klima", title: "Eska Klima", src: "https://www.eskaklima.com/upload/4516257316.jpg", width: "416", height: "312" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 nv5q43" },
                                        //Element
                                        React.createElement( "img", { alt: "Eska Klima", title: "Eska Klima", src: "https://www.eskaklima.com/upload/1000652081.jpg", width: "416", height: "312" } )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default About;