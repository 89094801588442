//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as C28D6J } from "../../../Media/Icon/008.svg";
//Install
import Wall from "../Tab/Wall";
//Install
import Floor from "../Tab/Floor";
//Install
import Casette from "../Tab/Casette";
//Install
import Multiple from "../Tab/Multiple";

//Element
class Individual extends Component {
    //Element
    Wall_Tab = () => {
        //Element
        let Wall, Walls, Floor, Floors, Casette, Casettes, Multiple, Multiples
        //Element
        Wall      = document.getElementById( "Web_R65I1G" )
        //Element
        Walls     = document.getElementById( "Web_DDH849" )
        //Element
        Floor     = document.getElementById( "Web_P07J6U" )
        //Element
        Floors    = document.getElementById( "Web_I99649" )
        //Element
        Casette   = document.getElementById( "Web_A3T56F" )
        //Element
        Casettes  = document.getElementById( "Web_RB1110" )
        //Element
        Multiple  = document.getElementById( "Web_U8Z089" )
        //Element
        Multiples = document.getElementById( "Web_R7640T" )
        //Element
        Wall.classList.add( "d-block" )
        //Element
        Walls.classList.add( "a30e10" )
        //Element
        Floor.classList.remove( "d-block" )
        //Element
        Floors.classList.remove( "a30e10" )
        //Element
        Casette.classList.remove( "d-block" )
        //Element
        Casettes.classList.remove( "a30e10" )
        //Element
        Multiple.classList.remove( "d-block" )
        //Element
        Multiples.classList.remove( "a30e10" )
    }
    //Element
    Floor_Tab = () => {
        //Element
        let Wall, Walls, Floor, Floors, Casette, Casettes, Multiple, Multiples
        //Element
        Wall      = document.getElementById( "Web_R65I1G" )
        //Element
        Walls     = document.getElementById( "Web_DDH849" )
        //Element
        Floor     = document.getElementById( "Web_P07J6U" )
        //Element
        Floors    = document.getElementById( "Web_I99649" )
        //Element
        Casette   = document.getElementById( "Web_A3T56F" )
        //Element
        Casettes  = document.getElementById( "Web_RB1110" )
        //Element
        Multiple  = document.getElementById( "Web_U8Z089" )
        //Element
        Multiples = document.getElementById( "Web_R7640T" )
        //Element
        Wall.classList.remove( "d-block" )
        //Element
        Walls.classList.remove( "a30e10" )
        //Element
        Floor.classList.add( "d-block" )
        //Element
        Floors.classList.add( "a30e10" )
        //Element
        Casette.classList.remove( "d-block" )
        //Element
        Casettes.classList.remove( "a30e10" )
        //Element
        Multiple.classList.remove( "d-block" )
        //Element
        Multiples.classList.remove( "a30e10" )
    }
    //Element
    Casette_Tab = () => {
        //Element
        let Wall, Walls, Floor, Floors, Casette, Casettes, Multiple, Multiples
        //Element
        Wall      = document.getElementById( "Web_R65I1G" )
        //Element
        Walls     = document.getElementById( "Web_DDH849" )
        //Element
        Floor     = document.getElementById( "Web_P07J6U" )
        //Element
        Floors    = document.getElementById( "Web_I99649" )
        //Element
        Casette   = document.getElementById( "Web_A3T56F" )
        //Element
        Casettes  = document.getElementById( "Web_RB1110" )
        //Element
        Multiple  = document.getElementById( "Web_U8Z089" )
        //Element
        Multiples = document.getElementById( "Web_R7640T" )
        //Element
        Wall.classList.remove( "d-block" )
        //Element
        Floor.classList.remove( "d-block" )
        //Element
        Casette.classList.add( "d-block" )
        //Element
        Multiple.classList.remove( "d-block" )
        //Element
        Walls.classList.remove( "a30e10" )
        //Element
        Floors.classList.remove( "a30e10" )
        //Element
        Casettes.classList.add( "a30e10" )
        //Element
        Multiples.classList.remove( "a30e10" )
    }
    //Element
    Multiple_Tab = () => {
        //Element
        let Wall, Walls, Floor, Floors, Casette, Casettes, Multiple, Multiples
        //Element
        Wall      = document.getElementById( "Web_R65I1G" )
        //Element
        Walls     = document.getElementById( "Web_DDH849" )
        //Element
        Floor     = document.getElementById( "Web_P07J6U" )
        //Element
        Floors    = document.getElementById( "Web_I99649" )
        //Element
        Casette   = document.getElementById( "Web_A3T56F" )
        //Element
        Casettes  = document.getElementById( "Web_RB1110" )
        //Element
        Multiple  = document.getElementById( "Web_U8Z089" )
        //Element
        Multiples = document.getElementById( "Web_R7640T" )
        //Element
        Wall.classList.remove( "d-block" )
        //Element
        Floor.classList.remove( "d-block" )
        //Element
        Casette.classList.remove( "d-block" )
        //Element
        Multiple.classList.add( "d-block" )
        //Element
        Walls.classList.remove( "a30e10" )
        //Element
        Floors.classList.remove( "a30e10" )
        //Element
        Casettes.classList.remove( "a30e10" )
        //Element
        Multiples.classList.add( "a30e10" )
    }
    //Element
    render() {
         //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 g537rv" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 gl6479" },
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 r5163w" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_DDH849", name: "Web_DDH849", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3 a30e10", type: "button", onClick: this.Wall_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Klimalar" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_I99649", name: "Web_I99649", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3", type: "button", onClick: this.Floor_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Döşeme Tipi Klimalar" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_RB1110", name: "Web_RB1110", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3", type: "button", onClick: this.Casette_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Kaset Tipi Klimalar" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_R7640T", name: "Web_R7640T", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3", type: "button", onClick: this.Multiple_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Multi Sistem Klimalar" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:9 },
                    //Element
                    React.createElement( "div", { id: "Web_R65I1G", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0 d-block" },
                        //Element
                        React.createElement( Wall, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: "Web_P07J6U", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0" },
                        //Element
                        React.createElement( Floor, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: "Web_A3T56F", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0" },
                        //Element
                        React.createElement( Casette, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: "Web_U8Z089", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0" },
                        //Element
                        React.createElement( Multiple, {} )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Individual;