//Install
import React, { Component } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
//Install
import { ReactComponent as AL56P2 } from "../../../Media/Icon/010.svg";
//Install
import { ReactComponent as W434EF } from "../../../Media/Icon/009.svg";
//Install
import Product from "../Product";

//Element
class Hybrid extends Component {
    //Element
    render() {
        //Element
        SwiperCore.use([ Navigation, Pagination, Autoplay ])
        //Element
        const Slider_Hybrid = {
            //Element
            spaceBetween  : 12,
            //Element
            slidesPerView : 3,
            //Element
            navigation    : {
                //Element
                prevEl    : "#Web_P08963",
                //Element
                nextEl    : "#Web_DA9136"
            },
            //Element
            breakpoints   : { 
                //Element
                240       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 0
                },
                //Element
                320       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                480       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                768       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                1024       : {
                    //Element
                    slidesPerView : 3,
                    //Element
                    spaceBetween  : 12
                }
            }
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden g1t38v", ...Slider_Hybrid },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "45", Title: "Mitsubishi Kaset Tipi PLFY-M-VEM", Photo: "1373806583.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "46", Title: "Mitsubishi Kaset Tipi PLFY-P-VFM", Photo: "8410799872.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "47", Title: "Mitsubishi Kaset Tipi PLFY-P-VLMD", Photo: "5316785771.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "48", Title: "Mitsubishi Kaset Tipi PMFY-P-VBM", Photo: "2926451082.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "49", Title: "Mitsubishi Gizli Tavan PEFY-M-VMA2-A", Photo: "5006029911.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "50", Title: "Mitsubishi Gizli Tavan PEFY-M-VMA-A", Photo: "4850666955.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "51", Title: "Mitsubishi Gizli Tavan PEFY-P-VMA3", Photo: "1759608200.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "52", Title: "Mitsubishi Gizli Tavan PEFY-P-VMH(S)", Photo: "1371794441.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "53", Title: "Mitsubishi Gizli Tavan PEFY-P-VMHS-E", Photo: "5884485105.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "54", Title: "Mitsubishi Gizli Tavan PEFY-P-VMHS-E-F", Photo: "3183073090.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "55", Title: "Mitsubishi Gizli Tavan PEFY-P-VMR-E", Photo: "9935107506.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "56", Title: "Mitsubishi Gizli Tavan PEFY-P-VMS", Photo: "1813289040.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "57", Title: "Mitsubishi Asılı Tavan PCFY-P-VKM-E", Photo: "7688923327.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "58", Title: "Mitsubishi Döşeme PFFY-P-VCM-E", Photo: "0099161149.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "59", Title: "Mitsubishi Döşeme PFFY-P-VKM-E2", Photo: "4530345884.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "60", Title: "Mitsubishi Döşeme PFFY-P-VLEM", Photo: "4069403324.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "61", Title: "Mitsubishi ATW BOOSTER PWFY-P", Photo: "0083603483.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "62", Title: "Mitsubishi ATW HEX PWFY-EP", Photo: "0083603483.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "63", Title: "Mitsubishi Isı Geri Kaz. Taze Hava GUF-RD(H)", Photo: "1811737998.jpg" } )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( Product, { ID: "64", Title: "Mitsubishi Klima Sant. Kontrol PAC-AH", Photo: "4392784357.jpg" } )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 st39m0" },
                //Element
                React.createElement( "button", { id: "Web_P08963", name: "Web_P08963", className: "d-flex justify-content-center align-items-center p-0 position-relative rounded-circle w2966t", type: "button" },
                    //Element
                    React.createElement( AL56P2, {} )
                ),
                //Element
                React.createElement( "button", { id: "Web_DA9136", name: "Web_DA9136", className: "d-flex justify-content-center align-items-center p-0 position-relative rounded-circle a255yx", type: "button" },
                    //Element
                    React.createElement( W434EF, {} )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Hybrid;