//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { useParams } from "react-router-dom";
//Install
import Axios from "axios";
//Install
import Breadcrumb from "../Breadcrumb/Product";
//Install
import Photo from "../Shortcode/Product/Photo";
//Install
import Location from "../Shortcode/Product/Location";
//Install
import Headline from "../Shortcode/Product/Headline";
//Element
import About from "../Shortcode/Product/About";
//Install
import Features from "../Shortcode/Product/Features";
//Install
import Answer from "../Shortcode/Product/Answer";
//Install
import Similiar from "../Shortcode/Product/Similiar";

//Element
function Product(){
    //Element
    const { ID } = useParams()
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://www.eskaklima.com/API/LPLH3FV97T", {
                //Element
                params : { BKC7XQ : ID }
            })
            //Element
            C68006( Public.data )
        }
        //Element
        Fetch()
    }, [])
    //Element
    G7TI26.map( (Data) => {
        //Element
        let Site    = "  Eskaklima.com'da"
        //Element
        document.title = Data.TU292B + Site
        //Element
        document.getElementsByTagName( "meta" )[ "description" ].content = Data.CX4936
        //Element
        document.getElementsByTagName( "meta" )[ "keyword" ].content = Data.ME3YAN
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 m0734c" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 s70f4d" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 xj2868 f1j45n" },
                            //Element
                            G7TI26.map( (Data) => {
                                //Element
                                return React.createElement( Breadcrumb, { key: Data.BKC7XQ, ID: "0", Title: Data.TU292B, Target: window.location.href } )
                            })
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 k87517" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( Photo, {} ),
                        //Element
                        React.createElement( Location, {} )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 g0h616" },
                            //Element
                            React.createElement( Headline, {} ),
                            //Element
                            React.createElement( About, {} ),
                            //Element
                            React.createElement( Features, {} ),
                            //Element
                            React.createElement( Answer, {} )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Similiar, {} )
                    )
                )
            )
        )
    )
    //Element
    return(
        <main className="float-start w-100">
            { Content }
        </main>
    )
}
//Element
export default Product;