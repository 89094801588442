//Install
import React, { Component, useRef } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";
//Install
import Breadcrumb from "../Breadcrumb/Page";

//Element
const Support = () => {
    //Element
    document.title = "Teknik Destek Talebi - Mitsubishi Electric Klima : Eskaklima.com"
    //Element
    document.getElementsByTagName( "meta" )["description"].content = "Sürdürülebilir Bir Gelecek için Yüksek Enerji Tasarruflu A+++ Serisi Klimalarla Tanışın!"
    //Element
    const Form = useRef()
    //Element
    const sendEmail = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Success = document.getElementsByClassName( "n003cf" )[0]
        //Element
        emailjs.sendForm( "service_phb0kqi", "template_jq8iw7h", Form.current, "tWsrkFqyAVY20DPka" )
        //Element
        .then( (result) => {
            //Element
            console.log( result.text )
            //Element
            Success.classList.toggle( "d-none" )
            //Element
        }, (error) => {
            //Element
            console.log( error.text )
        })
    }
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 w531jt" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start e22q94" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 u4o95z" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center p750g9" }, "Teknik Destek Talebi" )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center f1j45n" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Breadcrumb, { ID: "0", Title: "Teknik Destek Talebi", Target: "/destek-talebi" } )
                    )
                )
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 mb-4 position-relative o6l4h3" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "figure", { className: "float-start w-100 p-0 m-0 q98p51" } )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 m-0 pa789b" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 g942i2" }, "Teknik Destek Talebi" ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 aq6402" }, "Tüm Bakım, Onarım ve Kurulum Taleplerinizi Buradan Gönderebilirsiniz." ),
                            //Element
                            React.createElement( "form", { id: "Web_B7B569", name: "Web_B7B569", ref: Form, className: "float-start w-100 p-0 m-0 mt-2 q664y3", onSubmit: sendEmail },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "input", { id: "Web_W3F966", name: "Web_W3F966", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white kn581v", type: "text", placeholder: "Adınız", required: "required" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "input", { id: "Web_Q3590B", name: "Web_Q3590B", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white kn581v", type: "text", placeholder: "Soyadınız", required: "required" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "input", { id: "Web_A4E818", name: "Web_A4E818", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white kn581v", type: "tel", placeholder: "Telefon numaranız", pattern: "[0-9]{3}[0-9]{3}[0-9]{4}", maxLength: "10", required: "required" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "input", { id: "Web_GW05E4", name: "Web_GW05E4", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white kn581v", type: "email", placeholder: "E-posta adresiniz", required: "required" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "select", { id: "Web_G7B02D", name: "Web_G7B02D", className: "float-start w-100 p-0 m-0 mt-4 hb0975", required: "required" },
                                                //Element
                                                React.createElement( "option", { value: "" }, "Şehir" ),
                                                //Element
                                                React.createElement( "option", { value: "Adana" }, "Adana" )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "select", { id: "Web_EG052L", name: "Web_EG052L", className: "float-start w-100 p-0 m-0 mt-4 hb0975", required: "required" },
                                                //Element
                                                React.createElement( "option", { value: "" }, "Semt" ),
                                                //Element
                                                React.createElement( "option", { value: "Seyhan" }, "Seyhan" ),
                                                //Element
                                                React.createElement( "option", { value: "Kozan" }, "Kozan" ),
                                                //Element
                                                React.createElement( "option", { value: "Yumurtalık" }, "Yumurtalık" ),
                                                //Element
                                                React.createElement( "option", { value: "Yüreğir" }, "Yüreğir" ),
                                                //Element
                                                React.createElement( "option", { value: "İmamoğlu" }, "İmamoğlu" ),
                                                //Element
                                                React.createElement( "option", { value: "Tufanbeyli" }, "Tufanbeyli" ),
                                                //Element
                                                React.createElement( "option", { value: "Çukurova" }, "Çukurova" ),
                                                //Element
                                                React.createElement( "option", { value: "Karataş" }, "Karataş" ),
                                                //Element
                                                React.createElement( "option", { value: "Feke" }, "Feke" ),
                                                //Element
                                                React.createElement( "option", { value: "Sarıçam" }, "Sarıçam" ),
                                                //Element
                                                React.createElement( "option", { value: "Karaisalı" }, "Karaisalı" ),
                                                //Element
                                                React.createElement( "option", { value: "Aladağ" }, "Aladağ" ),
                                                //Element
                                                React.createElement( "option", { value: "Ceyhan" }, "Ceyhan" ),
                                                //Element
                                                React.createElement( "option", { value: "Pozantı" }, "Pozantı" ),
                                                //Element
                                                React.createElement( "option", { value: "Saimbeyli" }, "Saimbeyli" )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "select", { id: "Web_I7Q34M", name: "Web_I7Q34M", className: "float-start w-100 p-0 m-0 mt-4 hb0975", required: "required" },
                                                //Element
                                                React.createElement( "option", { value: "" }, "Konu Belirtin" ),
                                                //Element
                                                React.createElement( "option", { value: "Kurulum Talebi" }, "Kurulum Talebi" ),
                                                //Element
                                                React.createElement( "option", { value: "Bakım Talebi" }, "Bakım Talebi" ),
                                                //Element
                                                React.createElement( "option", { value: "Tamir Talebi" }, "Tamir Talebi" ),
                                                //Element
                                                React.createElement( "option", { value: "Diğer" }, "Diğer" )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "textarea", { id: "Web_R06T51", name: "Web_R06T51", className: "float-start w-100 p-0 ps-4 pe-4 pt-3 m-0 mt-4 bg-white j229ex", placeholder: "Nasıl Yardımcı Olabiliriz?", rows: "5", cols: "30", required: "required" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "button", { id: "Web_XZ821V", name: "Web_XZ821V", className: "float-start w-100 p-0 m-0 mt-4 mb-4 border-0 ph6e87 zk286n", type: "submit" }, "Hemen Gönder" )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 lh-1 d-none n003cf" }, "Tebrikler Başarı ile Gönderildi! En Kısa Sürede Dönüş Yapılacaktır." )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        <div className="float-start w-100">
            { Content }
        </div>
    )
}
//Element
export default Support;