//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as S40J0T } from "../../Media/Icon/007.svg";
//Install
import { ReactComponent as E89380 } from "../../Media/Icon/012.svg";
//Install
import { ReactComponent as EE4E96 } from "../../Media/Icon/013.svg";
//Install
import { ReactComponent as Z3I480 } from "../../Media/Icon/014.svg";

//Element
class Bottom extends Component {
    //Element
    Home = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "/"
    }
    //Element
    Product = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "/klimalar"
    }
    //Element
    Support = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "/destek-talebi"
    }
    //Element
    Call = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "tel:+903222488899"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 h6o36k" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 jp7b74" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "button", { id: "Web_K328LP", name: "Web_K328LP", className: "float-start w-100 p-0 m-0 bg-white border-0 t750s6", type: "button", onClick: this.Call },
                                //Element
                                React.createElement( "span", { className: "float-start w-50 p-0 m-0 text-truncate text-start h215h1" }, "Mitsubishi Electric Klima" ),
                                //Element
                                React.createElement( "span", { className: "float-start w-50 p-0 m-0 text-truncate text-end q16cz9" }, "0322 248 88 99" )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o3e49b" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-25 p-0 m-0 k2d228" },
                                //Element
                                React.createElement( "button", { id: "Web_M5651K", name: "Web_M5651K", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 h906vf", type: "button", onClick: this.Home },
                                    //Element
                                    React.createElement( E89380, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-25 p-0 m-0 k2d228" },
                                //Element
                                React.createElement( "button", { id: "Web_C9B847", name: "Web_C9B847", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 h906vf", type: "button", onClick: this.Product },
                                    //Element
                                    React.createElement( Z3I480, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-25 p-0 m-0 k2d228" },
                                //Element
                                React.createElement( "button", { id: "Web_V5T55P", name: "Web_V5T55P", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 h906vf", type: "button", onClick: this.Support },
                                    //Element
                                    React.createElement( EE4E96, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-25 p-0 m-0 k2d228" },
                                //Element
                                React.createElement( "button", { id: "Web_B41KA8", name: "Web_B41KA8", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 h906vf", type: "button", onClick: this.Call },
                                    //Element
                                    React.createElement( S40J0T, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Bottom;