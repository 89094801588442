//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as BJ210O } from "../../Media/Icon/005.svg";
//Install
import { ReactComponent as X873F4 } from "../../Media/Icon/008.svg";

//Element
class Navigation extends Component {
    //Element
    Call = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "tel:+903222488899"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-fixed d-none k729de" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { id: "Web_PF388P", name: "Web_PF388P", className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 position-relative qm453k", type: "button", onClick: this.Call },
                            //Element
                            React.createElement( "div", { className: "d-flex justify-content-center align-items-center p-0 m-0 bg-transparent text-center rounded-circle r929rj" },
                                //Element
                                React.createElement( BJ210O, {} )
                            ),
                            //Element
                            React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-3 m0qi92" }, "Çağrı Merkezi" ),
                            //Element
                            React.createElement( "span", { className: "float-start w-auto p-0 m-0 position-absolute end-0 top-0 qv695v" }, "0322 248 88 99" )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 s0081p" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Mitsubishi Electric Klima", href: "/" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 k85s3n" }, "Mitsubishi Electric Klima" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 v814mt" },
                                        //Element
                                        React.createElement( X873F4, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kurumsal", href: "/kurumsal" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 k85s3n" }, "Kurumsal" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 v814mt" },
                                        //Element
                                        React.createElement( X873F4, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Medya", href: "/medya" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 k85s3n" }, "Medya" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 v814mt" },
                                        //Element
                                        React.createElement( X873F4, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Klimalar", href: "/klimalar" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 k85s3n" }, "Klimalar" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 v814mt" },
                                        //Element
                                        React.createElement( X873F4, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Bakım ve Tamir", href: "/destek-talebi" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 k85s3n" }, "Bakım ve Tamir" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 v814mt" },
                                        //Element
                                        React.createElement( X873F4, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Referanslar", href: "/referanslar" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 k85s3n" }, "Referanslar" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 v814mt" },
                                        //Element
                                        React.createElement( X873F4, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kariyer", href: "/kariyer" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 k85s3n" }, "Kariyer" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 v814mt" },
                                        //Element
                                        React.createElement( X873F4, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Bize Ulaşın", href: "/bize-ulasin" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 k85s3n" }, "Bize Ulaşın" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 v814mt" },
                                        //Element
                                        React.createElement( X873F4, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Navigation;