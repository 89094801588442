//Install
import React, { Component, useRef } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";

//Element
const Contact = () => {
    //Element
    const Form = useRef()
    //Element
    const sendEmail = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Success = document.getElementsByClassName( "h3t02u" )[0]
        //Element
        emailjs.sendForm( "service_phb0kqi", "template_jq8iw7h", Form.current, "tWsrkFqyAVY20DPka" )
        //Element
        .then( (result) => {
            //Element
            Success.classList.toggle( "d-none" )
            //Element
        }, (error) => {
            //Element
            console.log( error.text )
        })
    }
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 ps-5 pe-5 m-0 mt-5 nxl288" },
        //Element
        React.createElement( "h5", { className: "float-start w-100 p-0 m-0 mt-4 text-center lh-1 s624xi" }, "Nasıl Yardımcı Olabiliriz?" ),
        //Element
        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center r3u8c9" }, "Merhaba; Bugün Hangi Konuda Yardımcı Olabiliriz?" ),
        //Element
        React.createElement( "form", { id: "Web_D418UT", name: "Web_D418UT", ref: Form, className: "float-start w-100 p-0 m-0 o6885d", onSubmit: sendEmail },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "input", { id: "Web_W3F966", name: "Web_W3F966", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white s3704x", type: "text", placeholder: "Adınız", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "input", { id: "Web_Q3590B", name: "Web_Q3590B", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white s3704x", type: "text", placeholder: "Soyadınız", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "input", { id: "Web_A4E818", name: "Web_A4E818", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white s3704x", type: "tel", placeholder: "Telefon numaranız", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "input", { id: "Web_GW05E4", name: "Web_GW05E4", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white s3704x", type: "email", placeholder: "E-posta adresiniz", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "textarea", { id: "Web_R06T51", name: "Web_R06T51", className: "float-start w-100 p-0 ps-4 pe-4 pt-3 m-0 mt-4 bg-white af8k72", rows: "5", cols: "30", placeholder: "Nasıl Yardımcı Olabiliriz?", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "button", { id: "Web_R840JV", name: "Web_R840JV", className: "float-start w-100 p-0 m-0 mt-4 mb-4 border-0 h84059", type: "submit" }, "Hemen Gönder" )
                    )
                ),
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 lh-1 d-none h3t02u" }, "Tebrikler Başarı ile Gönderildi! En Kısa Sürede Dönüş Yapılacaktır." )
                    )
                )
            )
        )
    )
    //Element
    return(
        <div className="float-start w-100">
            { Content }
        </div>
    )
}
//Element
export default Contact;