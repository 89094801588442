//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Breadcrumb from "../Breadcrumb/Page";
//Install
import { ReactComponent as Z0B36L } from "../../Media/Icon/008.svg";

//Element
class Question extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Sıkça Sorulan Sorular - Mitsubishi Electric Klima : Eskaklima.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Sürdürülebilir Bir Gelecek için Yüksek Enerji Tasarruflu A+++ Serisi Klimalarla Tanışın!"
    }
    //Element
    Show = (e) => {
        //Element
        let Path = e.nativeEvent.composedPath()[2].children[1]
        //Element
        Path.classList.toggle( "d-none" )        
        //Element
        let Icon   = e.nativeEvent.composedPath()[1].children[2]
        //Element
        Icon.classList.toggle( "p9wjuz" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w531jt" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start e22q94" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u4o95z" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center p750g9" }, "Sıkça Sorulan Sorular" )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center f1j45n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Sıkça Sorulan Sorular", Target: "/sikca-sorulan-sorular" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 mb-4 position-relative o6l4h3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 q98p51" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-5 pt-0 pb-0 m-0 i02f1u" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 iy941h" }, "Sıkça Sorulan Sorular ve Cevapları" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 oi4351" }, "Bugüne kadar en çok aldığımız soruların cevaplarını sizin için derledik." )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-5 pt-0 pb-0 m-0 mt-1 zi8021" },
                                //Element
                                React.createElement( "button", { id: "Web_QOA996", name: "Web_QOA996", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 d593vy", type: "button", onClick: this.Show },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 eb068o" },
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start pg153m" }, "1." ),
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start go895o" }, "Klima Satın Alırken Nelere Dikkat Edilmelidir?" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 m-0 text-start zv2t48" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        ),
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-4 m-0 d-none h0w94i" },
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-start n4yo21" }, "Mitsubishi Klima Satın Alırken Mekânınızın Özellikleri, Hacmi, Işık Alma ve Yalıtım Durumu Göz Önüne Alınmalıdır." )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_XG510P", name: "Web_XG510P", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 d593vy", type: "button", onClick: this.Show },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 eb068o" },
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start pg153m" }, "2." ),
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start go895o" }, "Klima Kullanımında Dikkat Edilecek Hususlar Nelerdir?" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 m-0 text-start zv2t48" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        ),
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-4 m-0 d-none h0w94i" },
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-start n4yo21" }, "Mitsubishi Klima Çalışırken Motoruna Herhangi Bir Nedenden Dolayı Dokunmayın." ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-start n4yo21" }, "Elektrik Kesintisi Durumunda Klimanın Sigortalarını Hemen Kapatın." ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-start n4yo21" }, "Klimanın Kablolarında Oluşacak Herhangi Bir Hasar Durumunda Klimayı Kapatın ve Yetkili Servisi Çağırın." )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_B9E0U0", name: "Web_B9E0U0", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 d593vy", type: "button", onClick: this.Show },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 eb068o" },
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start pg153m" }, "3." ),
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start go895o" }, "Mitsubishi Klima'nın Bakımı Nasıl Yapılmalıdır?" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 m-0 text-start zv2t48" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        ),
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-4 m-0 d-none h0w94i" },
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-start n4yo21" }, "Mitsubishi Klima'nın Mevsim Bakımları, Motor Bakımları, Filtre Bakımı Gibi Teknik Bilgi Gerektiren Bakım Çalışmaları;" ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-start n4yo21" }, "Mutlaka Bir Mitsubishi Electric Yetkili Servisi Tarafından Yapılmalıdır." )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_J17L5J", name: "Web_J17L5J", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 d593vy", type: "button", onClick: this.Show },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 eb068o" },
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start pg153m" }, "4." ),
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start go895o" }, "Klima Kapasiteleri Hangi Kriterlere Göre Belirlenir?" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 m-0 text-start zv2t48" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        ),
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-4 m-0 d-none h0w94i" },
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-start n4yo21" }, "İklimlendirme Yapılacak Olan Mekanın Yalıtımı, Kat Durumu, Çatı Kat Olması, Pencelerin Konumu, Bulunduğu Cephenin Konumu;" ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-start n4yo21" }, "Gibi Belirli Faktörler Kapasite Tercihinde Önemli Kriterler Olacaktır." )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_R1D9N1", name: "Web_R1D9N1", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 d593vy", type: "button", onClick: this.Show },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 eb068o" },
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start pg153m" }, "5." ),
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start go895o" }, "Klima Seçiminde Hangi Özelliklere Dikkat Edilmelidir?" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 m-0 text-start zv2t48" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        ),
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-4 m-0 d-none h0w94i" },
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-start n4yo21" }, "Klimanın Kurulacağı Mekanı Belirledikten Sonra Filtre Sistemi, Enerji Verimliliğine ve BTU Değerine Dikkat Etmeniz Gerekir." )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_WX4632", name: "Web_WX4632", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 d593vy", type: "button", onClick: this.Show },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 eb068o" },
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start pg153m" }, "6." ),
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start go895o" }, "Mitsubishi Merkezi Sistem Klimalar Nerelerde Kullanılır?" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 m-0 text-start zv2t48" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        ),
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-4 m-0 d-none h0w94i" },
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-start n4yo21" }, "Mitsubishi Merkezi Sistem Klimaları Oteller, Ofis, Hastaneler, Sinema Salonları ve Havalimanı Gibi Mekanlarda Kullanılmaktadır." )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_A3NB97", name: "Web_A3NB97", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 d593vy", type: "button", onClick: this.Show },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 eb068o" },
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start pg153m" }, "7." ),
                                        //Element
                                        React.createElement( "span", { className: "float-start p-0 m-0 text-start go895o" }, "Mitsubishi VRF Sistem Nedir?" ),
                                        //Element
                                        React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 m-0 text-start zv2t48" },
                                            //Element
                                            React.createElement( Z0B36L, {} )
                                        ),
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-4 m-0 d-none h0w94i" },
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-start n4yo21" }, "Mitsubishi VRF Sistem Klimaları Değişken Soğutucu Akışkan Debileri Anlamına Gelmektedir." ),
                                        //Element
                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-start n4yo21" }, "Bir Dış Ünite ile Birden Fazla İç Ünitenin Kontrol Edildiği Klima Sistemi VRF Klima Olarak Adlandırılmaktadır." )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Question;