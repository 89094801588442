//Install
import React, { Component } from "react";

//Element
class Brand extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { id: "Web_SA461J", name: "Web_SA461J", className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 f6n64y", type: "button", onClick: this.Home },
            //Element
            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-start m96l9m" }, "eskaklima" )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Brand;