//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Brand from "./Header/Brand";
//Install
import Menu from "./Header/Menu";
//Install
import Cookie from "./Header/Cookie";
//Install
import Slider from "./Header/Slider.js";
//Install
import Top from "./Mobile/Top";
//Install
import Navigation from "./Mobile/Navigation";
//Install
import GET from "./Function/GET";

//Element
class Header extends Component {
    //Element
    componentDidMount(){
        //Element
        let U55C47       = document.getElementsByClassName( "l7zj25" )[0]
        //Element
        U55C47.innerHTML = "Bu sitenin daha iyi, daha hızlı ve güvenli çalışması için çerez kullanılmaktadır. Site üzerinde kullanılan çerezler hakkında detaylı bilgi almak için Çerez Politikası'nı incelemenizi rica ederiz."
        //Element
        let Panel  = document.getElementsByClassName( "x3287n" )[0]
        //Element
        let Mobile = document.getElementsByClassName( "n7j1g8" )[0]
        //Element
        let Cookie = GET( "Cookie" )
        //Element
        if( Cookie == "OK" ){
            //Element
            Panel.classList.add( "d-none" )
            //Element
            Mobile.classList.add( "d-none" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 x3287n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:11 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 l7zj25" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Cookie, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g67d7f" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Brand, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:7 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Menu, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Slider, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 x80247" },
                //Element
                React.createElement( Top, {} ),
                //Element
                React.createElement( Navigation, {} )
            )
        )
        //Element
        return (
            <header className="float-start w-100">
                { Content }
            </header>
        );
    }
}
//Element
export default Header;