//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
//Install
import Slider from "./Content/Slider";
//Install
import Category from "./Content/Category";
//Install
import Nav from "./Content/Nav";
//Install
import Product from "./Content/Product";
//Install
import Location from "./Content/Location";
//Install
import Form from "./Content/Form";
//Install
import Reference from "./Content/Reference";
//Install
import Install from "./Shortcode/Content/Install";
//Install
import Repair from "./Shortcode/Content/Repair";
//Install
import Maintenance from "./Shortcode/Content/Maintenance";
//Install
import Supply from "./Shortcode/Content/Supply";
//Install
import House from "./Shortcode/Service/House";
//Install
import Institution from "./Shortcode/Service/Institution";
//Install
import Hotel from "./Shortcode/Service/Hotel";
//Install
import Hospital from "./Shortcode/Service/Hospital";
//Install
import { ReactComponent as G5A68Y } from "../Media/Icon/009.svg";

//Element
class Main extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title  = "Adana Mitsubishi Klima - Mitsubishi Klima Adana Yetkili Servisi : Eskaklima.com"
        //Element
        const T010N1    = document.querySelectorAll( ".m0p16l" )
        //Element
        const DR8296    = "400"
        //Element
        T010N1.forEach( K20626 => {
            //Element
            const S88614 = () => {
                //Element
                const BG76A1  = + K20626.getAttribute( "value" )
                //Element
                const RU103C  = + K20626.innerText
                //Element
                const A1601O  = BG76A1 / DR8296
                //Element
                if( RU103C < BG76A1 ){
                    //Element
                    K20626.innerText = Math.ceil( RU103C + A1601O )
                    //Element
                    setTimeout( S88614, 75 )
                } else {
                    //Element
                    K20626.innerText = BG76A1
                }
            }
            //Element
            S88614()
        })
    }
    //Element
    Support = () => {
        //Element
        window.location.href = "/destek-talebi"
    }
    //Element
    render() {
        //Element
        SwiperCore.use([ Navigation, Pagination, Autoplay ])
        //Element
        const Sliders = {
            //Element
            spaceBetween  : 0,
            //Element
            slidesPerView : 1
        }
        //Element
        const Categorys = {
            //Element
            spaceBetween  : 0,
            //Element
            slidesPerView : 1,
            //Element
            navigation    : {
                //Element
                prevEl    : "#Web_H23140",
                //Element
                nextEl    : "#Web_H46402"
            },
            //Element
            loop          : true
        }
        //Element
        const References = {
            //Element
            spaceBetween  : 12,
            //Element
            slidesPerView : 7,
            //Element
            autoplay      : {
                //Element
                delay     : 5000
            },
            //Element
            breakpoints   : { 
                //Element
                240       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 0
                },
                //Element
                320       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                480       : {
                    //Element
                    slidesPerView : 3,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                768       : {
                    //Element
                    slidesPerView : 5,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                1024       : {
                    //Element
                    slidesPerView : 7,
                    //Element
                    spaceBetween  : 12
                }
            },
            //Element
            loop          : true
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 p2628r" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Sliders },
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start w-100" },
                                        //Element
                                        React.createElement( Slider, { ID: "0", Title: "Mitsubishi Electric Klima - Dört Mevsim Klima", Headline: "Mitsubishi Electric Klima Sistemleri", Summary: "Hava değişimleri keyfinizi kaçırmasın! Yüksek enerji verimliliğine sahip Mitsubishi Electric Klima'lar ile havalar değişir, konforunuz aynı kalır.", Describe: "Mitsubishi Electric Klima, Tam Tasarruflu Klimalar, Enerji Tasarruflu Modeller.", Target: "/klimalar" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 p53us8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Install, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Repair, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Maintenance, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Supply, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s0b6y6" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 y8017f" },
                                //Element
                                React.createElement( "figure", { className: "d-flex w-100 justify-content-center align-items-center b62125" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 we012g" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-center y6rh29" }, "Mitsubishi Klima Yetkili Bayi" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 text-center n2017b" }, "Adana'da Mitsubishi Electric Klimalar için Teknik Bakım Onarım. Tamir. Yedek Parça. Tedarik. Hizmetleri Sunulmaktadır." ),
                                //Element
                                React.createElement( "button", { id: "Web_R1HU54", name: "Web_R1HU54", className: "d-flex justify-content-center align-items-center bg-white m-0 mt-3 u97139", type: "button", onClick: this.Support },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 nt098c" }, "Hemen Bakın" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 ms-1 position-relative a81e4p" },
                                        //Element
                                        React.createElement( G5A68Y, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 z752vl" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( House, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Institution, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Hotel, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Hospital, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-0 c0046v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 n1945q" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 lh-1 q26962" }, "Mitsubishi Electric Klima" ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 d8t671" }, "Mekanınız ve konforunuz için doğru klima ile tanıştınız mı?" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 r66d51" }, "Yüksek verim avantajı sağlayan klimalar ile 4 mevsim konfor, 4 mevsim tasarruf!" )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-3 p667l3" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Categorys },
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start w-100" },
                                        //Element
                                        React.createElement( Category, { ID: "0", Title: "Klimalar", Summary: "Mitsubishi Klimalar, çeşitli kapasite seçenekleri ve farklı renk alternatifleri ile ihtiyaç duyulan klima çözümünün esnek şekilde karşılanmasını sağlar.", Photo: "0515610625.jpg", Value: "10", Target: "/klimalar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start w-100" },
                                        //Element
                                        React.createElement( Category, { ID: "0", Title: "Ticari Klimalar", Summary: "Mitsubishi Ticari Klimalar, küçük ve büyük kapasite seçenekleri ile kompakt yapılarına rağmen mükemmel klima deneyimini garanti eder.", Photo: "8923821596.jpg", Value: "10", Target: "/klimalar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start w-100" },
                                        //Element
                                        React.createElement( Category, { ID: "0", Title: "VRF Klima Sistemleri", Summary: "Mitsubishi VRF Klima Sistemleri, homojen hava dağılımı ve her iç ünitenin bağımsız kontrol kolaylığını sunar.", Photo: "5791495844.jpg", Value: "24", Target: "/klimalar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start w-100" },
                                        //Element
                                        React.createElement( Category, { ID: "0", Title: "Hidronik Sistemler", Summary: "Mitsubishi Klima Sistemleri, sürdürülebilir bir gelecek için geliştirilen çevreye duyarlı ürünleri ile bireysel ve profesyonel çözümler sunar.", Photo: "7850373166.png", Value: "01", Target: "/klimalar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start w-100" },
                                        //Element
                                        React.createElement( Category, { ID: "0", Title: "Havalandırma Çözümleri", Summary: "Mitsubishi Klima Sistemleri, sürdürülebilir bir gelecek için geliştirilen çevreye duyarlı ürünleri ile bireysel ve profesyonel çözümler sunar.", Photo: "0028460251.jpg", Value: "03", Target: "/klimalar" } )
                                    ),
                                    //Element
                                    React.createElement( Nav, {} )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 dxq251" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex flex-column w-100 justify-content-center align-items-center p-0 pt-5 m-0 q6510h" },
                                //Element
                                React.createElement( "h4", { className: "float-start w-100 p-0 m-0 mt-4 text-center lh-1 k43h8o" }, "Mitsubishi Electric Klima" ),
                                //Element
                                React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-2 text-center e2lm99" }, "Kullanıcı yorumları doğrultusunda belirlenen popüler klimalar" )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Product, { ID: "0", Title: "HareME", Photo: "2078294575.jpg", Target: "/urun/mitsubishi-hareme-msz-hr-klima/3" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Product, { ID: "0", Title: "Kirigamine Zen", Photo: "3984444313.jpg", Target: "/urun/mitsubishi-kirigamine-zen-msz-ef-klima/4" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Product, { ID: "0", Title: "EnviroME", Photo: "7937772245.jpg", Target: "/urun/mitsubishi-envirome-msz-ap-klima/2" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 p1z96d" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Location, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Form, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n70rm0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 mb-4 ja557z" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...References },
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "DeFacto", Photo: "1928845858.jpg", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "Doğa Koleji", Photo: "3409053421.jpg", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "Sanko", Photo: "7654430547.jpg", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "Ender", Photo: "3291357118.png", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "Groseri", Photo: "3484990513.jpg", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "Çetinkaya", Photo: "0062734545.jpg", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "TAT Kimya", Photo: "6509600504.png", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "Erten Otel", Photo: "3265101116.png", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "Mondi", Photo: "9232420161.png", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start" },
                                        //Element
                                        React.createElement( Reference, { ID: "0", Title: "Cigerci Hadi", Photo: "3859058853.jpg", Target: "/referanslar" } )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Main;