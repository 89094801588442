//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as C28D6J } from "../../../Media/Icon/008.svg";
//Install
import Blow from "../Tab/Blow";
//Install
import Hang from "../Tab/Hang";
//Install
import Slot from "../Tab/Slot";
//Install
import Hall from "../Tab/Hall";
//Install
import Kitchen from "../Tab/Kitchen";

//Element
class Professional extends Component {
    //Element
    Kitchen_Tab = () => {
        //Element
        let Kitchen, Kitchens, Hall, Halls, Slot, Slots, Hang, Hangs, Blow, Blows
        //Element
        Kitchen     = document.getElementById( "Web_T532D3" )
        //Element
        Kitchens = document.getElementById( "Web_Y6AS53" )
        //Element
        Hall        = document.getElementById( "Web_C89LD7" )
        //Element
        Halls    = document.getElementById( "Web_BB2242" )
        //Element
        Slot        = document.getElementById( "Web_B26708" )
        //Element
        Slots    = document.getElementById( "Web_U413MB" )
        //Element
        Hang        = document.getElementById( "Web_KE782M" )
        //Element
        Hangs    = document.getElementById( "Web_MH330J" )
        //Element
        Blow        = document.getElementById( "Web_K9279Q" )
        //Element
        Blows    = document.getElementById( "Web_S267ZZ" )
        //Element
        Kitchen.classList.add( "d-block" )
        //Element
        Kitchens.classList.add( "a30e10" )
        //Element
        Hall.classList.remove( "d-block" )
        //Element
        Halls.classList.remove( "a30e10" )
        //Element
        Slot.classList.remove( "d-block" )
        //Element
        Slots.classList.remove( "a30e10" )
        //Element
        Hang.classList.remove( "d-block" )
        //Element
        Hangs.classList.remove( "a30e10" )
        //Element
        Blow.classList.remove( "d-block" )
        //Element
        Blows.classList.remove( "a30e10" )
    }
    //Element
    Hall_Tab = () => {
        //Element
        let Kitchen, Kitchens, Hall, Halls, Slot, Slots, Hang, Hangs, Blow, Blows
        //Element
        Kitchen  = document.getElementById( "Web_T532D3" )
        //Element
        Kitchens = document.getElementById( "Web_Y6AS53" )
        //Element
        Hall     = document.getElementById( "Web_C89LD7" )
        //Element
        Halls    = document.getElementById( "Web_BB2242" )
        //Element
        Slot     = document.getElementById( "Web_B26708" )
        //Element
        Slots    = document.getElementById( "Web_U413MB" )
        //Element
        Hang     = document.getElementById( "Web_KE782M" )
        //Element
        Hangs    = document.getElementById( "Web_MH330J" )
        //Element
        Blow     = document.getElementById( "Web_K9279Q" )
        //Element
        Blows    = document.getElementById( "Web_S267ZZ" )
        //Element
        Kitchen.classList.remove( "d-block" )
        //Element
        Kitchens.classList.remove( "a30e10" )
        //Element
        Hall.classList.add( "d-block" )
        //Element
        Halls.classList.add( "a30e10" )
        //Element
        Slot.classList.remove( "d-block" )
        //Element
        Slots.classList.remove( "a30e10" )
        //Element
        Hang.classList.remove( "d-block" )
        //Element
        Hangs.classList.remove( "a30e10" )
        //Element
        Blow.classList.remove( "d-block" )
        //Element
        Blows.classList.remove( "a30e10" )
    }
    //Element
    Slot_Tab = () => {
        //Element
        let Kitchen, Kitchens, Hall, Halls, Slot, Slots, Hang, Hangs, Blow, Blows
        //Element
        Kitchen     = document.getElementById( "Web_T532D3" )
        //Element
        Kitchens = document.getElementById( "Web_Y6AS53" )
        //Element
        Hall        = document.getElementById( "Web_C89LD7" )
        //Element
        Halls    = document.getElementById( "Web_BB2242" )
        //Element
        Slot        = document.getElementById( "Web_B26708" )
        //Element
        Slots    = document.getElementById( "Web_U413MB" )
        //Element
        Hang        = document.getElementById( "Web_KE782M" )
        //Element
        Hangs    = document.getElementById( "Web_MH330J" )
        //Element
        Blow        = document.getElementById( "Web_K9279Q" )
        //Element
        Blows    = document.getElementById( "Web_S267ZZ" )
        //Element
        Kitchen.classList.remove( "d-block" )
        //Element
        Kitchens.classList.remove( "a30e10" )
        //Element
        Hall.classList.remove( "d-block" )
        //Element
        Halls.classList.remove( "a30e10" )
        //Element
        Slot.classList.add( "d-block" )
        //Element
        Slots.classList.add( "a30e10" )
        //Element
        Hang.classList.remove( "d-block" )
        //Element
        Hangs.classList.remove( "a30e10" )
        //Element
        Blow.classList.remove( "d-block" )
        //Element
        Blows.classList.remove( "a30e10" )
    }
    Hang_Tab = () => {
        //Element
        let Kitchen, Kitchens, Hall, Halls, Slot, Slots, Hang, Hangs, Blow, Blows
        //Element
        Kitchen     = document.getElementById( "Web_T532D3" )
        //Element
        Kitchens = document.getElementById( "Web_Y6AS53" )
        //Element
        Hall        = document.getElementById( "Web_C89LD7" )
        //Element
        Halls    = document.getElementById( "Web_BB2242" )
        //Element
        Slot        = document.getElementById( "Web_B26708" )
        //Element
        Slots    = document.getElementById( "Web_U413MB" )
        //Element
        Hang        = document.getElementById( "Web_KE782M" )
        //Element
        Hangs    = document.getElementById( "Web_MH330J" )
        //Element
        Blow        = document.getElementById( "Web_K9279Q" )
        //Element
        Blows    = document.getElementById( "Web_S267ZZ" )
        //Element
        Kitchen.classList.remove( "d-block" )
        //Element
        Kitchens.classList.remove( "a30e10" )
        //Element
        Hall.classList.remove( "d-block" )
        //Element
        Halls.classList.remove( "a30e10" )
        //Element
        Slot.classList.remove( "d-block" )
        //Element
        Slots.classList.remove( "a30e10" )
        //Element
        Hang.classList.add( "d-block" )
        //Element
        Hangs.classList.add( "a30e10" )
        //Element
        Blow.classList.remove( "d-block" )
        //Element
        Blows.classList.remove( "a30e10" )
    }
    //Element
    Blow_Tab = () => {
        //Element
        let Kitchen, Kitchens, Hall, Halls, Slot, Slots, Hang, Hangs, Blow, Blows
        //Element
        Kitchen     = document.getElementById( "Web_T532D3" )
        //Element
        Kitchens = document.getElementById( "Web_Y6AS53" )
        //Element
        Hall        = document.getElementById( "Web_C89LD7" )
        //Element
        Halls    = document.getElementById( "Web_BB2242" )
        //Element
        Slot        = document.getElementById( "Web_B26708" )
        //Element
        Slots    = document.getElementById( "Web_U413MB" )
        //Element
        Hang        = document.getElementById( "Web_KE782M" )
        //Element
        Hangs    = document.getElementById( "Web_MH330J" )
        //Element
        Blow        = document.getElementById( "Web_K9279Q" )
        //Element
        Blows    = document.getElementById( "Web_S267ZZ" )
        //Element
        Kitchen.classList.remove( "d-block" )
        //Element
        Kitchens.classList.remove( "a30e10" )
        //Element
        Hall.classList.remove( "d-block" )
        //Element
        Halls.classList.remove( "a30e10" )
        //Element
        Slot.classList.remove( "d-block" )
        //Element
        Slots.classList.remove( "a30e10" )
        //Element
        Hang.classList.remove( "d-block" )
        //Element
        Hangs.classList.remove( "a30e10" )
        //Element
        Blow.classList.add( "d-block" )
        //Element
        Blows.classList.add( "a30e10" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 g537rv" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 gl6479" },
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 r5163w" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_S267ZZ", name: "Web_S267ZZ", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3 a30e10", type: "button", onClick: this.Blow_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Üflemeli Kaset Tipi" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_MH330J", name: "Web_MH330J", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3", type: "button", onClick: this.Hang_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Asılı Tavan Tipi" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_U413MB", name: "Web_U413MB", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3", type: "button", onClick: this.Slot_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Kanallı Gizli Tavan Tipi" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_BB2242", name: "Web_BB2242", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3", type: "button", onClick: this.Hall_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Salon Tipi" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "button", { id: "Web_Y6AS53", name: "Web_Y6AS53", className: "float-start w-100 p-0 ps-5 pe-5 m-0 text-start position-relative e445c3", type: "button", onClick: this.Kitchen_Tab },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 ja1e68" }, "Asılı Tavan Tipi" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 lh-1 z16h4i" },
                                        //Element
                                        React.createElement( C28D6J, {} )
                                    )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:9 },
                    //Element
                    React.createElement( "div", { id: "Web_K9279Q", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0 d-block" },
                        //Element
                        React.createElement( Blow, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: "Web_KE782M", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0" },
                        //Element
                        React.createElement( Hang, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: "Web_B26708", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0" },
                        //Element
                        React.createElement( Slot, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: "Web_C89LD7", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0" },
                        //Element
                        React.createElement( Hall, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: "Web_T532D3", className: "float-start w-100 p-5 ps-2 pe-5 m-0 t0i4b0" },
                        //Element
                        React.createElement( Kitchen, {} )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Professional;