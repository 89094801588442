//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import Axios from "axios";

//Element
function Headline(){
    //Element
    const { ID } = useParams();
    //Element
    const [ H34F4X, A5S052 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://www.eskaklima.com/API/LPLH3FV97T", {
                //Element
                params : { BKC7XQ : ID }
            })
            //Element
            A5S052( Public.data )
        }
        //Element
        Fetch()
    }, [])
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0" },
        //Element
        H34F4X.map( (Data) => {
            //Element
            return React.createElement( "div", { key: Data.BKC7XQ, className: "float-start w-100 p-0 m-0" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-truncate uh95c8" },  Data.TU292B ),
                //Element
                React.createElement( "div", { className: "float-start p-0 m-0 mt-2 o5m9z5" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate k51ma6" }, "Mitsubishi Electric" )
                ),
                //Element
                React.createElement( "div", { className: "float-start p-0 m-0 mt-2 o5m9z5" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate fmb269" }, Data.GAG1A7 )
                ),
                //Element
                React.createElement( "div", { className: "float-start p-0 m-0 mt-2 o5m9z5" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate t35d8f" }, Data.XIK5F3 )
                )
            )
        })
    )
    //Element
    return(
        <div className="float-start w-100">
            { Content }
        </div>
    )
}
//Element
export default Headline;