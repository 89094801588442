//Install
import React, { Component } from "react";

//Element
class Submenu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "float-end p-0 m-0 d48yli" },
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Bireysel Verilerin Korunması", href: "/bireysel-verileri-koruma" }, "Bireysel Verilerin Korunması" )
            ),
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Çerez Politikaları", href: "/bireysel-verileri-koruma" }, "Çerez Politikaları" )
            ),
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "float-start", title: "Başvuru Formu", href: "/bireysel-verileri-koruma" }, "Başvuru Formu" )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Submenu;