//Install
import React, { Component } from "react";

//Element
class Maintenance extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex justify-content-between align-items-center p-0 m-0 mt-4 x57506" },
            //Element
            React.createElement( "span", { className: "float-start p-0 m-0 lh-1 y3vo86" }, "Bakım Hizmeti" ),
            //Element
            React.createElement( "span", { className: "float-end p-0 m-0 lh-1 m0p16l", value: "26" } )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Maintenance;