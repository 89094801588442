//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-relative" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 f630xx" }, "Kurumsal" ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 h5zzvk" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Hakkımızda", href: "/kurumsal" }, "Hakkımızda" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Medya", href: "/medya" }, "Medya" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Kariyer", href: "/kariyer" }, "Kariyer" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Referanslar", href: "/referanslar" }, "Referanslar" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Sıkça Sorulan Sorular", href: "/sikca-sorulan-sorular" }, "Sıkça Sorulan Sorular" )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-relative" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 f630xx" }, "Klimalar" ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 h5zzvk" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Ticari Klimalar", href: "/klimalar" }, "Ticari Klimalar" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Klimalar", href: "/klimalar" }, "Klimalar" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "VRF Klima Sistemleri", href: "/klimalar" }, "VRF Klima Sistemleri" )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-relative" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 f630xx" }, "Hidronik Sistemler" ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 h5zzvk" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Chiller Sistemleri", href: "/klimalar" }, "Chiller Sistemleri" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Modüler Chiller Sistemleri", href: "/klimalar" }, "Modüler Chiller Sistemleri" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Heat Pump Chiller Sistemleri", href: "/klimalar" }, "Heat Pump Chiller Sistemleri" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Fancoil Sistemleri", href: "/klimalar" }, "Fancoil Sistemleri" )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-relative" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 f630xx" }, "Havalandırma Çözümleri" ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 h5zzvk" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Bireysel Çözümler", href: "/klimalar" }, "Bireysel Çözümler" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Ticari Çözümler", href: "/klimalar" }, "Ticari Çözümler" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Klima Santrali", href: "/klimalar" }, "Klima Santrali" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Paket Tip Klimalar", href: "/klimalar" }, "Paket Tip Klimalar" )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-relative" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 f630xx" }, "Bakım ve Tamir" ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 mb-5 h5zzvk" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Kurulum Talebi", href: "/destek-talebi" }, "Kurulum Talebi" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Tamir Talebi", href: "/destek-talebi" }, "Tamir Talebi" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Bakım Talebi", href: "/destek-talebi" }, "Bakım Talebi" )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-relative" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 f630xx" }, "Bize Ulaşın" ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 mb-5 h5zzvk" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Bilgi Almak İçin", href: "/bize-ulasin" }, "Bilgi Almak İçin" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Öneriler İçin", href: "/bize-ulasin" }, "Öneriler İçin" )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", title: "Şikayetler İçin", href: "/bize-ulasin" }, "Şikayetler İçin" )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Menu;